export function Checkbox({ label = "", name = "", disabled = false, value = false, onChange = () => {}, ...options }) {
  return (
    <div className="form-check">
      <input
        type="checkbox"
        name={name}
        id={name}
        className="form-check-input"
        style={{ cursor: "pointer" }}
        disabled={disabled}
        checked={value}
        value={value}
        onChange={(e) => onChange(e.target.checked)}
        {...options}
      />
      <label className="form-check-label pe-auto" style={{ cursor: "pointer" }} htmlFor={name}>
        {label}
      </label>
    </div>
  );
}
