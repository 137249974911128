/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";

import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router";

import {
  approveOrReject,
  formSchemaPresent,
  getComments,
  getcurrentStatus,
  getFormData,
  putNextStatus,
  resolveComments,
  getCurrentApproval,
  putNsRequired,
  postNCRRFileNumber,
  sendNotifications,
} from "../../api/currentApplications";
import { pipelineApplicationSchema } from "../../schema/pipeLineFormSchema";
import FormFilledStatic from "./formFilledStatic";
import Rejected from "../rejectedForm/RejectedForm";
import InitalReviewLoading from "../loaders/InitalReviewLoading";
import CommonNavigation from "../commonNavigation/CommonNavigation";
import PromptComponent from "../promptComponent/PromptComponent";
import { useAtoms } from "../../hooks/useAtoms";
import { useUser } from "../../hooks/useUser";

function ApplicationStatic(props) {
  const { setHideNavigation } = useAtoms();
  setHideNavigation(false);
  let history = useHistory();
  const [applicationData, setApplicationData] = useState();
  const [loading, setLoading] = useState(true);
  const [allComments, setAllComments] = useState([]);
  const [laodSubmit, setLoadSubmit] = useState(false);
  const [resolvedComment, setResolvedComments] = useState([]);
  const [formSchema, setFormSchema] = useState(pipelineApplicationSchema);
  const [formName, setFormName] = useState("");
  const [error, setError] = useState(false);
  const [otherFormData, setOtherFormData] = useState(null);
  const [rejected, setRejected] = useState(false);
  const [reviewedBy, setReviewedBy] = useState([]);
  const [nsRequired, setNsRequired] = useState();
  const [check, SetCheck] = useState("");
  const [hntbFileNumber, setHNTBFileNumber] = useState();
  const [hntbFileSave, setHntbFileSave] = useState();
  const [applicationTitle, setApplicationTitle] = React.useState();
  const [notification, setNotification] = useState({});
  const [singleApplicant, setSingleApplicant] = useState(false);
  const [singleApprover, setSingleApprover] = useState(false);
  const [singleTeam, setSingleTeam] = useState(false);
  const [hntbFileNo, setHntbFileNo] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  let ItemId = props.match.params.applicationID;

  const { setNavigation } = useAtoms();

  setNavigation(`Engineering Review ${" "} ${applicationTitle ? applicationTitle : ItemId} `);

  const user = useUser() || {};
  const { roleName = "", id: contactRoleId, contact = {} } = user;

  React.useEffect(() => {
    const fetchData = async () => {
      let newRes = await getcurrentStatus(props.match.params.applicationID);
      SetCheck(newRes.data.data.currentStep);
    };
    fetchData();
  }, []);

  useEffect(() => {
    getFormData(props.match.params.applicationID)
      .then(({ data }) => {
        if (data.rejected) {
          setApplicationData(data);
          setRejected(true);
          setLoading(false);
        } else {
          setReviewedBy(data.reviewBy);
          setFormName(data.formName);
          setApplicationTitle(data.ApplicationTitle);
          setApplicationData(data.applicationData);
          setNsRequired(data.nsRequired);
          setHNTBFileNumber(data.HNTBFileNumber);
          setHntbFileSave(data.HNTBFileNumber);
          delete data.applicationData;
          setOtherFormData(data);
          getAllComments();
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
    getFormSchema();
  }, []);

  function getFormSchema() {
    formSchemaPresent(props.match.params.applicationID)
      .then((data) => {
        if (data.data.present) {
          const newSchema = JSON.parse(data.data.schema);
          setFormSchema(JSON.parse(newSchema));
        }
      })
      .catch((err) => console.log(err));
  }

  function getUnique(comments) {
    var uniqueComments = [comments[0]];
    comments.forEach((v) => {
      if (!uniqueComments.filter((d) => d.questionID === v.questionID).length) {
        uniqueComments.push(v);
      }
    });
    return uniqueComments;
  }
  function handleApprove() {
    const data = {
      rejected: false,
      applicationID: props.match.params.applicationID,
    };
    approveOrReject(data)
      .then(({ data }) => {
        alert("Application was approved successfully and will be sent For Engineering Review.");
        putNextStatus(ItemId, 3);
        history.push("/dashboard");
      })
      .catch((err) => console.log(err));
  }

  function handleCommentResolve(questionIDToResolve) {
    const data = {
      questionID: questionIDToResolve,
      resolvedBy: contact.email,
      applicationID: props.match.params.applicationID,
    };
    resolveComments(data)
      .then(({ data }) => {
        getAllComments();
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  function getAllComments() {
    getComments(props.match.params.applicationID)
      .then(({ data }) => {
        setResolvedComments(data.comments.engineeringComments.resolved);
        setAllComments(data.comments);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function enableTab(tabId) {
    const element = document.getElementById(tabId);
    const closest = element.closest(".accordion-collapse");
    closest.classList.add("show");
  }
  const handleSave = (data) => {
    PutncrrFileNumber();
    setHntbFileNo(true);
  };
  const PutncrrFileNumber = async () => {
    const formData = new FormData();
    formData.append("HNTBFileNumber", JSON.stringify(hntbFileNumber));
    let rr = await postNCRRFileNumber({
      applicationID: props.match.params.applicationID,
      HNTBFileNumber: hntbFileNumber,
    });
  };

  const moveToNext = async (e) => {
    setLoadSubmit(true);
    setLoadSubmit(false);
    e.applicationID = ItemId;
    let rs = await getCurrentApproval(e);

    setReviewedBy(rs.data.reviews.reviewBy);
    let length = nsRequired ? 3 : 2;
    if (rs.data.reviews.reviewBy.length === length) {
      await putNextStatus(ItemId, 1);
      history.push(`/draftAgreement/${props.match.params.applicationID}`);
    }
  };
  const nsFunction = async (e) => {
    let response = await putNsRequired({
      applicationID: props.match.params.applicationID,
      nsRequired: e.target.checked,
    });
    setNsRequired(response.data.nsRequired);
  };

  const commonEngineering = async () => {
    try {
      setApproveLoading(true);
      await moveToNext({
        reviewBy: "Engineering Representative",
      });
      setApproveLoading(false);
    } catch (error) {
      setApproveLoading(false);
      console.log(error);
      alert(error);
    }
  };
  const editApplication = () => {
    if (formName === "form210") {
      history.push(`/form210/${props.match.params.applicationID}`);
    }
    if (formName === "form220") {
      history.push(`/form220/${props.match.params.applicationID}`);
    }
  };
  let approverCommentId = allComments && allComments.engineeringComments && allComments.engineeringComments.approver && allComments.engineeringComments.approver.map((d) => d.questionID);

  var uniqueCommentsArray = [...new Set(approverCommentId)];

  let teamCommentId = allComments && allComments.engineeringComments && allComments.engineeringComments.team && allComments.engineeringComments.team.map((d) => d.questionID);

  uniqueCommentsArray = [...uniqueCommentsArray, ...new Set(teamCommentId)];

  let applicationCommentsId = allComments && allComments.engineeringComments && allComments.engineeringComments.applicant && allComments.engineeringComments.applicant.map((d) => d.questionID);

  uniqueCommentsArray = [...uniqueCommentsArray, ...new Set(applicationCommentsId)];

  let uniqueNum = [...new Set(applicationCommentsId)];

  uniqueCommentsArray = [...new Set(uniqueCommentsArray)];

  let applicationResolvedId = allComments && allComments.engineeringComments && allComments.engineeringComments.resolved && allComments.engineeringComments.resolved.map((d) => d.questionID);

  let valId = uniqueNum && uniqueNum.filter((value) => !applicationResolvedId.includes(value));

  let resolvedArray = applicationResolvedId && applicationResolvedId.length;

  const sendNotification = async (commentType, notificationTo) => {
    setNotification({});
    let data = {
      reviewType: "engineeringComments",
      commentType: commentType,
      applicationID: props.match.params.applicationID,
      notificationTo: notificationTo,
      custID: contactRoleId,
    };

    let datas = await sendNotifications(data);
    setNotification(datas.data.responseData.commentType);
    setSingleApplicant(false);
    setSingleApprover(false);
    setSingleTeam(false);
  };

  return error ? (
    <div>Not a valid form page</div>
  ) : !rejected ? (
    <div className="review-page">
      <div className={laodSubmit ? "submitting" : null}></div>
      <PromptComponent applicationId={props.match.params.applicationID} />
      <div className="d-flex flex-column">
        <div className="border-bottom mb-4 bg-white">
          <div className="w-75 mx-auto">
            <div className="">
              <CommonNavigation ItemId={props.match.params.applicationID} />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-3 sidebar">
            <div className="progress-outer position-sticky">
              <h2 className="mt-3">Comments Overview</h2>
              <div className="progress-col">
                <div className="right">
                  {formSchema.sections.map((v, i) => (
                    <>
                      <div className={`${"#" + v.label.split(" ").join("_") === window.location.hash ? "right-link current" : "right-link"}`}>
                        <a href={`#${v.label.split(" ").join("_").split("'").join("_") + i}`}>
                          <small className="">{v.label}</small>
                        </a>
                        <>
                          {roleName === "applicant" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.applicant)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card mt-3">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100">
                                                    {ReactHtmlParser(x.comment)}
                                                    <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}
                          {roleName === "Approving Representative" || roleName === "Operating Engineering Representative" || roleName === "Operating Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.approver)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card mt-3">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}

                          {roleName === "Approving Representative" || roleName === "Operating Engineering Representative" || roleName === "Operating Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.applicant)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card mt-3">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <div className="card-body p-3 pe-4 position-relative">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}

                          {roleName === "Approving Representative" || roleName === "Signing Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.team && allComments.engineeringComments.team)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card bg-transparent mt-3 border-0">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative bg-white">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="card-body p-3 pe-4 position-relative  bg-white">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a
                                                      className="position-absolute "
                                                      //href={`#${x.questionID}-ques`}
                                                      //data-bs-toggle="modal"
                                                      // data-bs-target="#ongoin-comment-modal"
                                                      /*onClick={() => {
                                        setQid(x.questionID);
                                      }}*/
                                                    >
                                                      &nbsp;
                                                    </a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}
                          {roleName === "Engineering Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.applicant)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card bg-transparent mt-3 border-0">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative bg-white">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="card-body p-3 pe-4 position-relative  bg-white">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a
                                                      className="position-absolute "

                                                      //data-bs-toggle="modal"
                                                      // data-bs-target="#ongoin-comment-modal"
                                                      /*onClick={() => {
                                      setQid(x.questionID);
                                    }}*/
                                                    >
                                                      &nbsp;
                                                    </a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}
                          {roleName === "Engineering Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.approver)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card bg-transparent mt-3 border-0">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative bg-white">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="card-body p-3 pe-4 position-relative  bg-white">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a
                                                      className="position-absolute "

                                                      //data-bs-toggle="modal"
                                                      // data-bs-target="#ongoin-comment-modal"
                                                      /*onClick={() => {
                                      setQid(x.questionID);
                                    }}*/
                                                    >
                                                      &nbsp;
                                                    </a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}
                          {roleName === "Engineering Representative" ? (
                            <>
                              {allComments.engineeringComments
                                ? v.questions.map((d, i) =>
                                    getUnique(allComments.engineeringComments.team && allComments.engineeringComments.team)
                                      .filter((v) => v && v.questionID && v.questionID === d.id)
                                      .map((x, i) => (
                                        <>
                                          <div key={i} className="card bg-transparent mt-3 border-0">
                                            {resolvedComment.filter((m) => m.questionID === x.questionID).length > 0 ? (
                                              <div className="card-body p-3 pe-4 position-relative bg-white">
                                                <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                  <a className="position-absolute ">&nbsp;</a>
                                                  <p className="mb-0 position-relative w-100 disable">
                                                    {ReactHtmlParser(x.comment)} <i className="mark-icon far fa-check text-muted position-absolute top-50 end-0"></i>
                                                  </p>
                                                </a>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="card-body p-3 pe-4 position-relative  bg-white">
                                                  <a href={`#${x.questionID}-ques`} onClick={() => enableTab(`${x.questionID}-ques`)}>
                                                    <a
                                                      className="position-absolute "

                                                      //data-bs-toggle="modal"
                                                      // data-bs-target="#ongoin-comment-modal"
                                                      /*onClick={() => {
                                      setQid(x.questionID);
                                    }}*/
                                                    >
                                                      &nbsp;
                                                    </a>
                                                    <p className="mb-0 position-relative w-100">{ReactHtmlParser(x.comment)}</p>
                                                  </a>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </>
                                      )),
                                  )
                                : null}
                            </>
                          ) : null}
                        </>
                      </div>
                    </>
                  ))}
                  <hr />
                </div>
              </div>

              {check <= 7 && roleName === "applicant" ? (
                <>
                  <div className="d-grid gap-2 mt-3 pb-5">
                    {notification == "applicant" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApprover === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a
                              onClick={() => sendNotification("applicant", "approvingRepresentativesANDengineeringRepresentatives", setSingleApprover(true))}
                              className="btn btn-primary text-white d-block  m-0"
                            >
                              <small>Submit Revisions</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : roleName === "Engineering Representative" ? (
                <>
                  <div className="d-grid gap-2 pb-5">
                    {notification == "approver" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApprover === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a
                              onClick={() => sendNotification("approver", "approvingRepresentativesANDoperatingRepresentativesANDengineeringRepresentatives", setSingleApprover(true))}
                              className="btn btn-primary text-white d-block  m-0"
                            >
                              <small>Notify Approvers</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                    {notification == "applicant" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApplicant === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a onClick={() => sendNotification("applicant", "applicant", setSingleApplicant(true))} className="btn btn-primary text-white d-block  m-0">
                              <small>Notify Applicant</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                    {notification == "team" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleTeam === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a onClick={() => sendNotification("team", "engineeringRepresentatives", setSingleTeam(true))} className="btn btn-primary text-white d-block  m-0">
                              <small>Notify My Team</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : roleName === "Operating Engineering Representative" || roleName === "Operating Representative" || roleName === "Approving Representative" ? (
                <>
                  <div className="d-grid gap-2 pb-5">
                    {notification == "approver" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApprover === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a
                              onClick={() => sendNotification("approver", "approvingRepresentativesANDoperatingRepresentativesANDengineeringRepresentatives", setSingleApprover(true))}
                              className="btn btn-primary text-white d-block  m-0"
                            >
                              <small>Notify Approvers</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                    {notification == "applicant" ? (
                      <>
                        <a className="btn btn-success d-block  m-0 disabled">
                          <small>
                            <i className="fas fa-check-double"></i> Notification Sent
                          </small>
                        </a>
                      </>
                    ) : (
                      <>
                        {singleApplicant === true ? (
                          <>
                            <a className="btn btn-outline-success d-block  m-0 disabled">
                              <small>
                                <i className="far fa-check"></i> Notification Sent
                              </small>
                            </a>
                          </>
                        ) : (
                          allComments &&
                          allComments.engineeringComments &&
                          allComments.engineeringComments.approver &&
                          (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                          uniqueCommentsArray &&
                          uniqueCommentsArray.length != resolvedArray && (
                            <a onClick={() => sendNotification("applicant", "applicant", setSingleApplicant(true))} className="btn btn-primary text-white d-block  m-0">
                              <small>Notify Applicant</small>
                            </a>
                          )
                        )}
                      </>
                    )}
                    {roleName === "Approving Representative" &&
                      (notification == "team" ? (
                        <>
                          <a className="btn btn-success d-block  m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </>
                      ) : (
                        <>
                          {singleTeam === true ? (
                            <>
                              <a className="btn btn-outline-success d-block  m-0 disabled">
                                <small>
                                  <i className="far fa-check"></i> Notification Sent
                                </small>
                              </a>
                            </>
                          ) : (
                            allComments &&
                            allComments.engineeringComments &&
                            allComments.engineeringComments.approver &&
                            (allComments.engineeringComments.approver.length > 0 || allComments.engineeringComments.team.length > 0 || allComments.engineeringComments.applicant.length > 0) &&
                            uniqueCommentsArray &&
                            uniqueCommentsArray.length != resolvedArray && (
                              <a onClick={() => sendNotification("team", "engineeringRepresentatives", setSingleTeam(true))} className="btn btn-primary text-white d-block  m-0">
                                <small>Notify My Team</small>
                              </a>
                            )
                          )}
                        </>
                      ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {loading ? (
            <InitalReviewLoading />
          ) : (
            <div className="col-lg-9">
              <div className="row justify-content-end my-3">
                {roleName === "applicant" && valId.length > 0 && check <= 7 ? (
                  <div className="col-md-4">
                    <div className="d-grid">
                      <button className="btn-primary btn-lg btn d-flex align-items-center justify-content-between py-3" onClick={editApplication}>
                        <span>Edit Application </span>
                        <i className="fal fa-chevron-right fs-3 ms-3"></i>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              {otherFormData.status === 0 ? null : (
                <div className="border-0  pb-4 position-sticky top-0 commentindex">
                  <div className="card-body p-0">
                    {roleName === "Approving Representative" || roleName === "Engineering Representative" ? (
                      <>
                        <div className="form-check form-switch">
                          <input
                            disabled={
                              check > 7 ||
                              (nsRequired === true &&
                                (reviewedBy.includes("Approving Representative") ||
                                  reviewedBy.includes("Engineering Representative") ||
                                  reviewedBy.includes("Operating Engineering Representative") ||
                                  reviewedBy.includes("Operating Representative")))
                            }
                            className="form-check-input"
                            type="checkbox"
                            checked={nsRequired}
                            id="flexSwitchCheckDefaul"
                            onClick={(e) => {
                              nsFunction(e);
                            }}
                          />
                          <label className="form-check-label" htmlFor="flexSwitchCheckDefaul">
                            Is Norfolk Southern Needed For Approval ?
                          </label>
                        </div>

                        <br />
                      </>
                    ) : null}
                    {roleName === "Engineering Representative" ? (
                      <>
                        {" "}
                        <label className="form-label">{check > 7 ? "HNTB File Number" : "HNTB File Number"}</label>
                        <div className="input-group">
                          <input
                            value={hntbFileNumber}
                            disabled={check > 7 || reviewedBy.includes("Engineering Representative")}
                            className="form-control"
                            onChange={(e) => {
                              setHNTBFileNumber(e.target.value);
                              setHntbFileNo(false);
                            }}
                          />
                          <button
                            // className="btn btn-primary"
                            className={(hntbFileNo === true && hntbFileNumber) || hntbFileSave ? "btn btn-success" : "btn btn-primary"}
                            onClick={(e) => {
                              handleSave(e.target.value);
                            }}
                          >
                            Save
                          </button>
                        </div>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {roleName !== "Engineering Representative" && (check > 7 || reviewedBy.includes("Engineering Representative")) ? (
                      <>
                        {" "}
                        <label className="form-label">HNTB File Number</label>
                        <div className="input-group">
                          <input value={hntbFileNumber} disabled={check > 7 || reviewedBy.includes("Engineering Representative")} className="form-control" />
                        </div>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    {roleName === "Engineering Representative" && (
                      <div className="row justify-content-end my-3">
                        {approveLoading ? (
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" style={{ color: "#86b7fe" }} role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {/* <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  forhtml="unitvalmap"
                                  className="form-label"
                                >
                                  UNIT VAL MAP for Unit
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="unitvalmap"
                                  id="unitvalmap"
                                  style={{
                                    height: "50px",
                                  }}
                                  disabled={(application.applicationStage !== 6) || !!application.unitvalmap}
                                  value={netsuiteState.unitvalmap}
                                  onChange={handleNetsuiteChange}
                                ></textarea>
                                <p className="text-danger">
                                  {netsuiteErrors?.unitvalmap}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  forhtml="parcelorigin"
                                  className="form-label"
                                >
                                  PARCEL ORIGIN for Unit
                                </label>
                                <div className="ui-widget select-col position-relative">
                                  <input
                                    style={{ height: "50px" }}
                                    type="text"
                                    id="automplete-1"
                                    className="form-control"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    placeholder="deed"
                                    value={netsuiteState.parcelorigin}
                                    disabled={(application.applicationStage !== 6) || !!application.parcelorigin}
                                  />
                                  <ul
                                    className="dropdown-menu w-100 rounded-0 pb-0"
                                    aria-labelledby="automplete-1"
                                    id="custom_pagination"
                                  >
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("deed")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Deed</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("charter")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Charter</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("both")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Both</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("other")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Other</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <p className="text-danger">
                                  {netsuiteErrors?.parcelorigin}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label forhtml="memo" className="form-label">
                                  SCOPE OF WORK for Opportunity
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="memo"
                                  name="memo"
                                  value={netsuiteState.memo}
                                  onChange={handleNetsuiteChange}
                                  disabled={(application.applicationStage !== 6)|| !!application.memoforopp}
                                ></textarea>
                                <p className="text-danger">
                                  {netsuiteErrors?.memo}
                                </p>
                              </div>
                            </div> */}
                          </>
                        )}
                      </div>
                    )}
                    {/* {roleName !== "Engineering Representative" && (!!application?.unitvalmap || !!application?.parcelorigin || !!application?.memoforopp) && (
                      <div className="row justify-content-end my-3">
                        {approveLoading ? (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border"
                              style={{ color: "#86b7fe" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <> */}
                    {/* <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  forhtml="unitvalmap"
                                  className="form-label"
                                >
                                  UNIT VAL MAP for Unit
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="unitvalmap"
                                  id="unitvalmap"
                                  style={{
                                    height: "50px",
                                  }}
                                  disabled={(application.applicationStage !== 6) || !!application.unitvalmap}
                                  value={netsuiteState.unitvalmap}
                                  onChange={handleNetsuiteChange}
                                ></textarea>
                                <p className="text-danger">
                                  {netsuiteErrors?.unitvalmap}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  forhtml="parcelorigin"
                                  className="form-label"
                                >
                                  PARCEL ORIGIN for Unit
                                </label>
                                <div className="ui-widget select-col position-relative">
                                  <input
                                    style={{ height: "50px" }}
                                    type="text"
                                    id="automplete-1"
                                    className="form-control"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    placeholder="deed"
                                    value={netsuiteState.parcelorigin}
                                    disabled={(application.applicationStage !== 6) || !!application.parcelorigin}
                                  />
                                  <ul
                                    className="dropdown-menu w-100 rounded-0 pb-0"
                                    aria-labelledby="automplete-1"
                                    id="custom_pagination"
                                  >
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("deed")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Deed</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("charter")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Charter</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("both")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Both</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                    <li
                                      className="border-bottom"
                                      onClick={() =>
                                        handleNetsuiteDropdownChange("other")
                                      }
                                    >
                                      <a className="dropdown-item d-flex justify-content-between">
                                        <span>Other</span>
                                        <span className="text text-primary">
                                          Select
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <p className="text-danger">
                                  {netsuiteErrors?.parcelorigin}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label forhtml="memo" className="form-label">
                                  SCOPE OF WORK for Opportunity
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="memo"
                                  name="memo"
                                  value={netsuiteState.memo}
                                  onChange={handleNetsuiteChange}
                                  disabled={(application.applicationStage !== 6)|| !!application.memoforopp}
                                ></textarea>
                                <p className="text-danger">
                                  {netsuiteErrors?.memo}
                                </p>
                              </div>
                            </div> */}
                    {/* </>
                        )}
                      </div>
                    )} */}

                    <div className="row text-center">
                      <div className="col-md-4 ">
                        <div className="d-grid">
                          {reviewedBy.length > 0 && reviewedBy.includes("Engineering Representative") ? (
                            <button disabled className="btn btn-outline-success text-start d-flex align-items-center">
                              <i className="fal fa-check fs-3 me-3"></i>
                              <span>
                                {" "}
                                Approved <br />
                                By HNTB
                              </span>
                            </button>
                          ) : roleName === "Engineering Representative" ? (
                            <button
                              onClick={commonEngineering}
                              disabled={uniqueCommentsArray && uniqueCommentsArray.length != resolvedArray}
                              className="btn btn-primary text-start d-flex align-items-center justify-content-between"
                            >
                              <span>
                                {" "}
                                For HNTB <br />
                                Approve Now
                              </span>
                              <i className="fal fa-chevron-right fs-3"></i>
                            </button>
                          ) : (
                            <div className="d-grid" style={{ color: "#8898AA" }}>
                              <a className="btn btn-outline-light text-start d-flex align-items-center pointer-none">
                                <i className="fal fa-clock fs-3 me-3"></i>
                                <span>
                                  Awaiting Approval
                                  <br />
                                  By HNTB
                                </span>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <div className="d-grid">
                          {reviewedBy.length > 0 && reviewedBy.includes("Approving Representative") ? (
                            <button disabled className="btn btn-outline-success text-start d-flex align-items-center">
                              {" "}
                              <i className="fal fa-check fs-3 me-3"></i>
                              <span>
                                Approved <br />
                                By NCRR
                              </span>
                            </button>
                          ) : roleName === "Approving Representative" && reviewedBy.includes("Engineering Representative") ? (
                            <button
                              disabled={uniqueCommentsArray && uniqueCommentsArray.length != resolvedArray}
                              onClick={() => {
                                moveToNext({
                                  reviewBy: "Approving Representative",
                                });
                              }}
                              className="btn btn-primary text-start d-flex align-items-center justify-content-between"
                            >
                              <span>
                                For NCRR <br />
                                Approve Now
                              </span>
                              <i className="fal fa-chevron-right fs-3"></i>
                            </button>
                          ) : (
                            <div className="d-grid">
                              <a className="btn btn-outline-light text-start d-flex align-items-center pointer-none" style={{ color: "#8898AA" }}>
                                <i className="fal fa-clock fs-3 me-3"></i>
                                <span>
                                  Awaiting Approval
                                  <br />
                                  By NCRR
                                </span>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>

                      {nsRequired ? (
                        <div className="col-md-4 ">
                          <div className="d-grid">
                            {reviewedBy.length > 0 && (reviewedBy.includes("Operating Engineering Representative") || reviewedBy.includes("Operating Representative")) ? (
                              <button disabled className="btn btn-outline-success text-start d-flex align-items-center">
                                <i className="fal fa-check fs-3 me-3"></i>
                                <span>
                                  Approved <br />
                                  By Norfolk Southern
                                </span>
                              </button>
                            ) : roleName === "Operating Engineering Representative" || roleName === "Operating Representative" ? (
                              <button
                                onClick={() => {
                                  moveToNext({
                                    reviewBy: roleName,
                                  });
                                }}
                                disabled={uniqueCommentsArray && uniqueCommentsArray.length != resolvedArray}
                                className="btn btn-primary text-start d-flex align-items-center justify-content-between"
                              >
                                <span>
                                  For Norfolk Southern <br />
                                  Approve Now
                                </span>
                                <i className="fal fa-chevron-right fs-3"></i>
                              </button>
                            ) : (
                              <div className="d-grid" style={{ color: "#8898AA" }}>
                                <a className="btn btn-outline-light text-start d-flex align-items-center pointer-none">
                                  <i className="fal fa-clock fs-3 me-3"></i>
                                  <span>
                                    Awaiting Approval
                                    <br />
                                    By Norfolk Southern
                                  </span>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              {otherFormData.status === 0 && roleName === "Approving Representative" ? (
                <div className="row justify-content-end my-3">
                  <div className="col-md-3">
                    <div className="d-grid">
                      <button type="button" className="btn-danger btn-lg btn d-flex align-items-center justify-content-between py-3" data-bs-toggle="modal" data-bs-target="#reject">
                        <span> Reject</span>
                        <i className="fal fa-times fs-3 ms-3"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-grid">
                      <button className="btn-primary btn-lg btn d-flex align-items-center justify-content-between py-3" onClick={handleApprove}>
                        <span>Approve & Proceed</span>
                        <i className="fal fa-chevron-right fs-3 ms-3"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {roleName != "applicant" && uniqueCommentsArray && uniqueCommentsArray.length != resolvedArray && (
                <>
                  <p className="text-center text-warning">Approval buttons are disabled until all comments are resolved</p>
                </>
              )}

              <FormFilledStatic
                formSchema={formSchema}
                applicationData={applicationData}
                applicationID={props.match.params.applicationID}
                allComments={allComments}
                resolvedComment={resolvedComment}
                handleCommentResolve={handleCommentResolve}
              />
              {/* 
                {roleName !=
                "Approving Representative" ? (
                  <Additional
                    applicationID={props.match.params.applicationID}
                  />
                ) : null} */}
              {otherFormData.status === 0 && roleName === "Approving Representative" ? (
                <div className="row justify-content-end my-3">
                  <div className="col-md-3">
                    <div className="d-grid">
                      <button type="button" className="btn-danger btn-lg btn d-flex align-items-center justify-content-between py-3" data-bs-toggle="modal" data-bs-target="#reject">
                        <span> Reject</span>
                        <i className="fal fa-times fs-3 ms-3"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-grid">
                      <button className="btn-primary btn-lg btn d-flex align-items-center justify-content-between py-3" onClick={handleApprove}>
                        <span>Approve & Proceed</span>
                        <i className="fal fa-chevron-right fs-3 ms-3"></i>
                      </button>
                    </div>
                  </div>

                  {/* <!--<span className="text-danger">3 comments awaiting resolution</span>--> */}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Rejected applicationData={applicationData} />
  );
}

export default ApplicationStatic;
