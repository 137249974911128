import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ToolTip from "../ui/ToolTip";
import { AddContactForm } from "../contacts/forms/AddContact";
import { getAssignedContacts, getConsultantContacts, getContactsByCompanyId } from "../../api/crm";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import Select from "react-select";
// Utility function to extract consultant roles from contacts
const extractConsultantRoles = (consultantContacts) => {
  return consultantContacts.reduce((acc, contact) => {
    const roles = contact.companyroles
      .filter((role) => role.company.companyType === "CONSULTANT")
      .map((role) => ({
        ...contact,
        companyId: role.company.id,
        companyName: role.company.name,
      }));
    return [...acc, ...roles];
  }, []);
};

// Sorting functions
const sortByName = (a, b) => a.name.localeCompare(b.name);
const sortByCompanyName = (a, b) => a.companyName.localeCompare(b.companyName);

const AssignContacts = ({ onSave, setAssignedContacts, companyContactApplication }) => {
  const { applicationID } = useParams();
  let [showAddModal, setAddShowModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [consultantContacts, setConsultantContacts] = useState([]);
  const [facilityContacts, setFacilityContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState({
    signatory: "",
    primaryRepresentative: "",
    billingRepresentative: "",
    engineeringRepresentative: "",
  });
  const [disabledContacts, setDisabledContacts] = useState({
    signatory: false,
    primaryRepresentative: false,
    billingRepresentative: false,
    engineeringRepresentative: false,
  });
  const [errors, setErrors] = useState({});
  const application = useApplication();
  const { roleName } = useUser();
  const user = useUser();
  const isApplicant = roleName === "applicant";

  const openModal = () => {
    setAddShowModal(true);
    document.body.classList.add("modal-open");
    const backdrop = document.createElement("div");
    backdrop.setAttribute("id", "modal-backdrops");
    backdrop.classList.add("modal-backdrop", "fade", "show");
    document.body.appendChild(backdrop);
  };

  const closeModal = () => {
    setAddShowModal(false);
    document.body.classList.remove("modal-open");
    const childElement = document.getElementById("modal-backdrops");
    childElement.parentNode.removeChild(childElement);
  };

  const fetchContacts = async (companyID) => {
    const res = await getContactsByCompanyId(companyID);
    if (res.data.status) {
      setContacts(res.data.data);
    }
  };

  // Fetch all consultant contacts
  const fetchConsultantContacts = async () => {
    const res = await getConsultantContacts();
    if (res.data.status) {
      const contacts = extractConsultantRoles(res.data.data);
      setConsultantContacts(contacts);
    }
  };

  // Fetch facility contacts
  const fetchFacilityContacts = async (applicationID) => {
    const res = await getAssignedContacts(applicationID);
    if (res.data.status) {
      const facilityCompany = res.data.data.facilityCompany;
      const response = await getContactsByCompanyId(facilityCompany.id);
      if (response.data.status) {
        const contacts = response.data.data;
        setContacts(contacts);
        setFacilityContacts(contacts);
      }
    }
  };

  // Handle consultant contact selection change
  const handleConsultantContactChange = (selectedContacts, consultantContacts, facilityContacts) => {
    if (selectedContacts.engineeringRepresentative) {
      const { companyId } = JSON.parse(selectedContacts.engineeringRepresentative) || {};
      const filteredConsultantContacts = consultantContacts.filter((contact) => contact.companyId === companyId);
      const combinedContacts = [...facilityContacts, ...filteredConsultantContacts];
      setContacts(combinedContacts);
      validateContactSelection(combinedContacts, selectedContacts);
    }
  };

  // Validate selected contacts
  const validateContactSelection = (contacts, selectedContacts) => {
    ["primaryRepresentative", "signatory"].forEach((field) => {
      if (selectedContacts[field]) {
        const isValid = contacts.some((contact) => contact.id === selectedContacts[field]);
        if (!isValid) {
          updateSelectedContacts(field, "");
          updateAssignedContacts(field, "");
        }
      }
    });
  };

  // Update selected contacts
  const updateSelectedContacts = (field, value) => {
    setSelectedContacts((prev) => ({ ...prev, [field]: value }));
  };

  // Update assigned contacts
  const updateAssignedContacts = (field, value) => {
    setAssignedContacts((prev) => ({ ...prev, [field]: value }));
  };

  // Set selected consultant contacts
  const setSelectedConsultantContacts = (consultantContacts, companyContactApplication, user) => {
    if (consultantContacts.length > 0) {
      const { consultantContact = {}, consultantCompany = {} } = companyContactApplication;
      let { id = "" } = consultantContact || {};

      if (!id && user.role.roleType === "CONSULTANT") {
        id = user.contact.id;
      }

      const contact = consultantContacts.find((contact) => {
        if (consultantCompany?.id) {
          return id === contact.id && contact?.companyId === consultantCompany?.id;
        }
        return id === contact.id && contact?.companyId === user.company?.id;
      });

      updateSelectedContacts("engineeringRepresentative", JSON.stringify(contact));
      updateAssignedContacts("engineeringRepresentative", contact?.id || "");
      updateAssignedContacts("engineeringRepresentativeCompany", contact?.companyId || "");
      if (user.role.roleType === "CONSULTANT") {
        setDisabledContacts((prev) => ({ ...prev, engineeringRepresentative: true }));
      }
    }
  };

  // Set selected facility contacts
  const setSelectedFacilityContacts = (applicantDetails) => {
    ["primaryContact", "billingContact", "signatoryContact"].forEach((contactType) => {
      const contactId = applicantDetails[contactType]?.id;
      let fieldName = contactType.replace("Contact", "Representative");
      if (contactType === "signatoryContact") {
        fieldName = contactType.replace("Contact", "");
      }
      updateSelectedContacts(fieldName, contactId);
      updateAssignedContacts(fieldName, contactId);
    });
  };

  // Handle contact selection change
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateSelectedContacts(name, value);
    updateAssignedContacts(name, value);

    // Clear the error message for the field being changed
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? "" : "*Required" }));

    if (name === "engineeringRepresentative") {
      let contact;
      try {
        contact = JSON.parse(value);
      } catch {
        contact = null;
      }
      updateAssignedContacts(name, contact?.id || "");
      updateAssignedContacts(`${name}Company`, contact?.companyId || "");
    }
  };

  // Handle save button click
  const handleSave = () => {
    const newErrors = {};
    if (!selectedContacts["primaryRepresentative"]) {
      newErrors["primaryRepresentative"] = "*Required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      onSave();
    }
  };

  useEffect(() => {
    fetchConsultantContacts();
  }, []);

  useEffect(() => {
    fetchFacilityContacts(applicationID);
  }, [applicationID]);

  useEffect(() => {
    setSelectedConsultantContacts(consultantContacts, companyContactApplication, user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantContacts, companyContactApplication, user]);

  useEffect(() => {
    if (facilityContacts.length > 0)
      if (application?.applicantDetails) {
        setSelectedFacilityContacts(application?.applicantDetails);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityContacts, application]);

  useEffect(() => {
    handleConsultantContactChange(selectedContacts, consultantContacts, facilityContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContacts, consultantContacts, facilityContacts]);

  const assignContacts = [
    {
      label: "Primary Representative",
      tooltipId: "PRIMARY REPRESENTATIVE",
      required: false,
      name: "primaryRepresentative",
      id: "Primary Representative",
      handleChange: handleChange,
      value: selectedContacts.primaryRepresentative,
      disabled: disabledContacts.primaryRepresentative || !isApplicant,
      options: contacts,
      getOptionValue: (item) => item.id,
      getValue: (item) => `${item.name} (${item.email})`,
      openModal: openModal,
      addIconDisabled: false,
      errors: errors.primaryRepresentative,
    },
    {
      label: "Signatory",
      tooltipId: "SIGNATORY",
      required: false,
      name: "signatory",
      id: "signatory",
      handleChange: handleChange,
      value: selectedContacts.signatory,
      disabled: disabledContacts.signatory || !isApplicant,
      options: contacts,
      getOptionValue: (item) => item.id,
      getValue: (item) => `${item.name} (${item.email})`,
      openModal: openModal,
      addIconDisabled: false,
    },
    {
      label: "Billing Representative",
      tooltipId: "BILLING REPRESENTATIVE",
      required: false,
      name: "billingRepresentative",
      id: "Billing Representative",
      handleChange: handleChange,
      value: selectedContacts.billingRepresentative,
      disabled: disabledContacts.billingRepresentative || !isApplicant,
      options: contacts,
      getOptionValue: (item) => item.id,
      getValue: (item) => `${item.name} (${item.email})`,
      openModal: openModal,
      addIconDisabled: false,
    },
    {
      label: "Engineering Representative",
      tooltipId: "ENGINEERING REPRESENTATIVE",
      required: false,
      name: "engineeringRepresentative",
      id: "Engineering Representative",
      handleChange: handleChange,
      value: selectedContacts.engineeringRepresentative,
      disabled: disabledContacts.engineeringRepresentative || !isApplicant,
      options: consultantContacts,
      getOptionValue: (item) => JSON.stringify(item),
      getValue: (item) => `${item.companyName} (${item.email})`,
      openModal: openModal,
      sortBy: sortByCompanyName,
      addIconDisabled: true,
    },
  ];

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-lg-10 m-auto">
          <div className="inner-form card ">
            <div className="border-bottom card-header">
              <h2 className="d-sm-inline-block">Assign Contacts</h2>
              <span
                className="ps-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Select the contacts from the list given below and the selected list used for approval in NCRR portal in future."
                data-bs-original-title=""
                title="Assign Contacts"
              >
                <i className="fas fa-question-circle text-dark"></i>
              </span>
            </div>
            <div className="card-body">
              <div id="company-details">
                {assignContacts.map(({ label, tooltipId, required, name, id, handleChange, value, disabled, options, getOptionValue, getValue, openModal, addIconDisabled, errors, sortBy }) => {
                  return (
                    <ContactSelect
                      label={label}
                      tooltipId={tooltipId}
                      required={required}
                      name={name}
                      id={id}
                      handleChange={handleChange}
                      value={value}
                      disabled={disabled}
                      options={options}
                      getOptionValue={getOptionValue}
                      getValue={getValue}
                      openModal={openModal}
                      addIconDisabled={addIconDisabled}
                      errors={errors}
                      sortBy={sortBy}
                    />
                  );
                })}
              </div>

              <hr className="m-0" />
              <div className="btn-col text-center d-flex justify-content-between mt-4">
                <button className="btn btn-primary btn-lg" onClick={handleSave} disabled={!isApplicant}>
                  Save and Proceed
                </button>
              </div>
            </div>
          </div>
          <AddContactForm showModal={showAddModal} setShowModal={setAddShowModal} getContact={fetchContacts} closeModal={closeModal} ID={"new-contact"} />
        </div>
      </div>
    </div>
  );
};

const ContactSelect = ({ label, tooltipId, required, name, id, handleChange, value, disabled, options, getValue, getOptionValue, openModal, addIconDisabled, errors, sortBy = sortByName }) => {
  return (
    <div className="row ">
      <div className="col">
        <div className="mt-3">
          <label className="form-label d-flex justify-content-between">
            <ToolTip data={tooltipId} text={label} />
            {!errors && <small className="me-5">{required ? "Required" : "Optional"}</small>}
            {errors && <small className="me-5 text-danger"> {errors}</small>}
          </label>
          <div className="d-flex justify-content-between">
            <AutoComplete
              errors={errors}
              name={name}
              id={id}
              handleChange={handleChange}
              value={value}
              disabled={disabled}
              options={options}
              sortBy={sortBy}
              getOptionValue={getOptionValue}
              getValue={getValue}
            />
            <div onClick={() => openModal()} className={`ml-4 text-primary ${addIconDisabled && "disabled"}`} style={{ cursor: "pointer" }}>
              <span className="fs-2 d-inline">+</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AutoComplete = ({ errors, name, id, handleChange, value, disabled, options, sortBy, getOptionValue, getValue }) => {
  const sortedOptions = options.sort(sortBy).map((item) => ({
    value: getOptionValue(item),
    label: getValue(item),
  }));

  const handleSelectChange = (selectedOption) => {
    handleChange({ target: { name, value: selectedOption ? selectedOption.value : "" } });
  };
  return (
    <Select
      className={"w-100 mb-3"}
      name={name}
      id={id}
      classNamePrefix={errors && "border-danger"}
      onChange={handleSelectChange}
      value={value && sortedOptions.find((option) => option.value === value)}
      options={sortedOptions}
      isDisabled={disabled}
      isClearable
    />
  );
};

export default AssignContacts;
