import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { triggerTransaction } from "../../api/Netsuite";

export const TriggerTransactionButton = ({ role }) => {
  const location = useLocation();
  const { pathname } = location;
  const paths = pathname?.split("/") || [];

  const [loading, setLoading] = useState(false);

  if (role !== "Approving Representative" && role !== "Approving Accountant") {
    return null;
  }

  const _id = paths[paths.length - 1];

  const trigger = async () => {
    setLoading(true);
    try {
      await triggerTransaction(_id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <button class="btn btn-primary" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-end align-items-center mb-2">
      <button onClick={trigger} className="btn btn-primary">
        Sync NetSuite
      </button>
    </div>
  );
};
