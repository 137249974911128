import axios from "axios";
import { API_URL } from ".";

//Dashboard Apis start
function getApplications(pageNumber, role, custID = false, sortType = false, sortBy = false, paymentmethod = false) {
  if (custID && custID !== "false") {
    const facilityId = localStorage.getItem("facilityId");
    return axios.get(`${API_URL}/applications/getApplications?custID=${custID}&pageNumber=${pageNumber}&role=${role}&sortType=${sortType}&sortBy=${sortBy}&facilityId=${facilityId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } else {
    return axios.get(`${API_URL}/applications/getApplications?pageNumber=${pageNumber}&role=${role}&sortType=${sortType}&sortBy=${sortBy}&paymentmethod=${paymentmethod}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

async function getApplicationsCount(customerId, facilityId) {
  const response = await axios.get(`${API_URL}/applications/getApplications?custID=${customerId}&pageNumber=${1}&role=${"applicant"}&sortType=${false}&sortBy=${false}&facilityId=${facilityId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
}

async function getAgreementsCount(customerId, facilityId) {
  const response = await axios.get(`${API_URL}/applications/getAgreements?custID=${customerId}&pageNumber=${1}&role=${"applicant"}&sortType=${false}&sortBy=${false}&facilityId=${facilityId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data;
}

function getAgreements(pageNumber, role, custID = false, sortType = false, sortBy = false) {
  if (custID) {
    const facilityId = localStorage.getItem("facilityId");
    return axios.get(`${API_URL}/applications/getAgreements?custID=${custID}&pageNumber=${pageNumber}&role=${role}&sortType=${sortType}&sortBy=${sortBy}&facilityId=${facilityId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } else {
    return axios.get(`${API_URL}/applications/getAgreements?pageNumber=${pageNumber}&role=${role}&sortType=${sortType}&sortBy=${sortBy}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

function filterApplications(pageNumber, stage, role, custID = false, sortType = false, sortBy = false, paymentmethod = false) {
  if (custID && custID !== "false") {
    const facilityId = localStorage.getItem("facilityId");
    return axios.get(`${API_URL}/applications/filterApplication?custID=${custID}&pageNumber=${pageNumber}&stage=${stage}&role=${role}&sortType=${sortType}&sortBy=${sortBy}&facilityId=${facilityId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } else {
    return axios.get(`${API_URL}/applications/filterApplication?pageNumber=${pageNumber}&role=${role}&stage=${stage}&sortType=${sortType}&sortBy=${sortBy}&paymentmethod=${paymentmethod}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

function getFilterLists(role, custID = false, paymentmethod = false) {
  if (custID) {
    const facilityId = localStorage.getItem("facilityId");
    return axios.get(`${API_URL}/applications/filterLists?role=${role}&custID=${custID}&facilityId=${facilityId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } else {
    return axios.get(`${API_URL}/applications/filterLists?role=${role}&paymentmethod=${paymentmethod}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

function searchApplications(custID, a, role) {
  const search = encodeURIComponent(a);
  const facilityId = localStorage.getItem("facilityId");
  return axios.get(`${API_URL}/applications/searchApplications?custID=${custID}&applicationTitle=${search}&role=${role}&facilityId=${facilityId}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

//Dashboard Apis End

function deleteSupportingFile(data) {
  return axios.post(`${API_URL}/agreement/draftAgreement/deleteSupportingFile`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getFormData(formID) {
  return axios.get(`${API_URL}/applications/${formID}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function getOperatingSigneeData() {
  return axios.get(`${API_URL}/user/user/getOperatingSignee/`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getComments(id) {
  return axios.get(`${API_URL}/applications/getComments/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function addComments(data) {
  return axios.post(`${API_URL}/applications/addComment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

// function allApplications(pageNumber, role) {
//   return axios.get(`${API_URL}/applications/?pageNumber=${pageNumber}&role=${role}`, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// }

// function getApplicationOwner(applicationID) {
//   return axios.get(`${API_URL}/applications/owner?id=${applicationID}`, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// }

function resolveComments(data) {
  return axios.put(`${API_URL}/applications/resolveComment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getFile(fileName) {
  return axios.get(`${API_URL}/file?fileName=${fileName}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getFileForDownload(fileName) {
  return axios.get(`${API_URL}/download?fileName=${fileName}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function formSchemaPresent(applicationID) {
  return axios.get(`${API_URL}/applications/schemaPresent/${applicationID}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

// function addAdditionalQues(data) {
//   return axios.post(`${API_URL}/applications/addAdditionalQues`, data, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// }

function getAdditionalQues(applicationID) {
  return axios.get(`${API_URL}/applications/getAdditionalQues/${applicationID}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function additionalQuesAnswered(data) {
  return axios.post(`${API_URL}/applications/additionalQuesAnswered`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function approveOrReject(data) {
  return axios.put(`${API_URL}/applications/aproveOrRejectApplication`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function compileAgreement(data) {
  return axios.post(`${API_URL}/agreement/compileAgreement/uploadLicense`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
      //"content-type": "multipart/form-data",
    },
  });
}
function draftAgreementPost(data) {
  return axios.post(`${API_URL}/agreement/draftAgreement/uploadLicense`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
      //"content-type": "multipart/form-data",
    },
  });
}
function resolveDraftLicense(data) {
  return axios.post(`${API_URL}/agreement/draftAgreement/resolveDraftLicense`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
      //"content-type": "multipart/form-data",
    },
  });
}
function getLicenseStatus(applicationID) {
  return axios.get(
    `${API_URL}/agreement/compileAgreement/licenseData?applicationID=${applicationID}`,

    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  );
}
function getcurrentStatus(ItemId) {
  if (ItemId !== null) {
    return axios.get(`${API_URL}/agreement/currentApplicationStage?applicationID=${ItemId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}
function putNextStatus(ItemId, number, status = "") {
  return axios.get(`${API_URL}/agreement/currentApplicationStage?applicationID=${ItemId}&updateStage=${number}&status=${status}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function getCurrentApproval(data) {
  return axios.post(`${API_URL}/agreement/review/approval`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function putNsRequired(data) {
  return axios.put(`${API_URL}/applications/nsRequired`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function postNCRRFileNumber(data) {
  return axios.post(`${API_URL}/applications/HNTBFileNumber`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function addCommentsDraftAgreement(data) {
  return axios.post(`${API_URL}/agreement/comments`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function getCommentsDraftAgreement(id) {
  return axios.get(`${API_URL}/agreement/compileAgreement/licenseData?applicationID=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function postApplication(data) {
  return axios.post(`${API_URL}/formsAPI/application`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function commentsFileUpload(data) {
  return axios.post(`${API_URL}/applications/addFile`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function unResolveComment(data) {
  return axios.post(`${API_URL}/applications/unresolveComment `, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function sendNotifications(data) {
  return axios.post(`${API_URL}/applications/sendCommentNotification `, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function sendNotify(data) {
  return axios.post(`${API_URL}/agreement/draftAgreement/notify `, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function putPaymentType(data) {
  return axios.put(`${API_URL}/applications/paymentType`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function putPaymentTransactionId(data) {
  return axios.put(`${API_URL}/applications/paymentTransactionId`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function putenvelopeId(data) {
  return axios.put(`${API_URL}/agreement/envelope`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function DeleteApplication(data) {
  return axios.post(`${API_URL}/agreement/deleteApplication`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function getApplicationData(data) {
  return axios.get(`${API_URL}/applications/getApplicationData?applicationID=${data}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function updateOperatingSignee(data) {
  return axios.post(`${API_URL}/agreement/compileAgreement/upDateOperatingSignee`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function validatePayment(custID, token) {
  return axios.get(`${API_URL}/applications/validatePayment?applicationID=${custID}&token=${token}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function approvepayment(applicationID) {
  return axios.get(`${API_URL}/applications/paymentApprove?applicationID=${applicationID}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
function updatePaymentDetails(data) {
  return axios.post(`${API_URL}/applications/updatePaymentDetails`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getACHNumberEmails() {
  return axios.get(`${API_URL}/applications/getACHNumberEmails`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function getCsvData() {
  return axios.get(`${API_URL}/applications/getCsvData`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function assignPaymentApi(data) {
  return axios.post(`${API_URL}/agreement/assignPayment`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function updateAgreementFile(data) {
  return axios.post(`${API_URL}/agreement/updateAgreementFile`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

function cancelApplicationApi(id) {
  return axios.delete(`${API_URL}/cancel/cancelApplication?id=${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}
// function saveNetsuiteData(data) {
//   return axios.post(`${API_URL}/applications/saveNetsuiteData`, data, {
//     headers: {
//       authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
// }

export {
  getFilterLists,
  filterApplications,
  updatePaymentDetails,
  DeleteApplication,
  getApplicationData,
  getFormData,
  addComments,
  getComments,
  // allApplications,
  // getApplicationOwner,
  resolveComments,
  getFile,
  getFileForDownload,
  formSchemaPresent,
  // addAdditionalQues,
  getAdditionalQues,
  additionalQuesAnswered,
  approveOrReject,
  compileAgreement,
  getLicenseStatus,
  getcurrentStatus,
  putNextStatus,
  draftAgreementPost,
  getCurrentApproval,
  putNsRequired,
  postNCRRFileNumber,
  addCommentsDraftAgreement,
  getCommentsDraftAgreement,
  postApplication,
  commentsFileUpload,
  searchApplications,
  unResolveComment,
  sendNotifications,
  sendNotify,
  putPaymentType,
  putPaymentTransactionId,
  putenvelopeId,
  getOperatingSigneeData,
  deleteSupportingFile,
  resolveDraftLicense,
  updateOperatingSignee,
  validatePayment,
  approvepayment,
  getApplications,
  getAgreements,
  getACHNumberEmails,
  getCsvData,
  assignPaymentApi,
  updateAgreementFile,
  // saveNetsuiteData,
  getAgreementsCount,
  getApplicationsCount,
  cancelApplicationApi,
};
