import axios from "axios";
const URL = process.env.REACT_APP_API_URL;

// function sendOtp(userInput) {
//   return axios.post(`${URL}/auth/otpLogin`, {
//     input: userInput,
//   });
// }

// function verifyOtpRepresentative(otp, userInput) {
//   return axios.post(`${URL}/auth/verifyOtpLoginRepresentative`, {
//     otp: parseInt(otp),
//     input: userInput,
//   });
// }

// function verifyOtp(otp, userInput) {
//   return axios.post(`${URL}/auth/verifyOtp`, {
//     otp: parseInt(otp),
//     input: userInput,
//   });
// }

// function verifyAccounting(email) {
//   return axios.post(`${URL}/auth/verifyApprovingAccounting`, {
//     email: email,
//   });
// }

const sendotp = (email) => {
  return axios.post(`${URL}/auth/sendOtp`, {
    email,
  });
};

const login = (email, otp) => {
  return axios.post(`${URL}/auth/login`, {
    email,
    otp,
  });
};

export {
  // sendOtp,
  // verifyOtp,
  // verifyOtpRepresentative,
  // verifyAccounting,
  sendotp,
  login,
};
