import React from "react";
import { useHistory } from "react-router-dom";
import { REACT_APP_NS_initial_fee, REACT_APP_NS_initial_fee_original, REACT_APP_NS_risk_finance_fee, REACT_APP_NS_risk_finance_fee_original } from "../../api";
import { useUser } from "../../hooks/useUser";
export default function AggreementGridViewCard(props) {
  let { v } = props;
  let { draft } = v.agreement || {};
  let { formData: formData1 } = (draft && draft[2]) || {};
  let activity_number = (formData1 && formData1[0]) || "";
  const { _id, formName, applicationData } = props.v || {};
  const { roleName = "" } = useUser() || {};

  let paymentAmount1 = "";

  let submittedDate;

  if (v.event1paymentOn) {
    submittedDate = new Date(v.event1paymentOn);
  }

  if (!v.event1paymentOn) {
    if (v.applicationStage < 6 || v.applicationStage === 26) {
      submittedDate = new Date();
    }

    if (v.applicationStage >= 6 && v.applicationStage !== 26) {
      submittedDate = new Date(2023, 5, 6);
    }
  }

  const event1StripeResponseArray = v.payment.event1StripeResponse || [];
  let event1StripeResponse = event1StripeResponseArray.some((each) => each.payment_status === "paid") || false;
  if (v.event1PaymentAmount) {
    paymentAmount1 = v.event1PaymentAmount;
  } else if (v.event1paymentType) {
    if (v.event1paymentType === "ACH" || v.event1paymentType === "Check") {
      if (submittedDate.getFullYear() >= 2024) {
        if (v.event1paymentType === "ACH" || v.event1paymentType === "Check") {
          paymentAmount1 = 4050;
        } else {
          paymentAmount1 = 4171.9;
        }
      } else {
        paymentAmount1 = 3700;
      }
    } else {
      paymentAmount1 = 3811;
    }
  } else if (event1StripeResponse === true) {
    paymentAmount1 = 3700;
  } else if (v.applicationStage === 25 && !event1StripeResponse) {
    paymentAmount1 = 3700;
  }

  let paymentAmount2 = "";
  let { agreement } = v;
  let chargesfee = agreement.compiled && agreement.compiled[1] && agreement.compiled[1].riskFinanceFee === "true";

  let event2Licensefee = agreement.draft.map((each) => each) || {};
  const licenseFees = event2Licensefee.map((item) => {
    if (item.hasOwnProperty("licenseFee") && Array.isArray(item.licenseFee)) {
      const validLicenseFees = item.licenseFee.filter((license) => typeof license === "object" && license.hasOwnProperty("license_fee"));
      return validLicenseFees.map((license) => license.license_fee);
    }
    return [];
  });
  const filteredLicenseFees = licenseFees.flat().filter((value) => value.trim() !== "");
  const NS_initial_fee =
    v.event2paymentType === "Check" || v.event2paymentType === "ACH"
      ? Number(REACT_APP_NS_initial_fee_original) + Number(filteredLicenseFees[0])
      : Number(REACT_APP_NS_initial_fee) + Number(filteredLicenseFees[0]) + Math.round(Number((filteredLicenseFees[0] * 3.01) / 100));

  const riskFees = chargesfee && (v.event2paymentType === "Check" || v.event2paymentType === "ACH") ? Number(REACT_APP_NS_risk_finance_fee_original) : Number(REACT_APP_NS_risk_finance_fee);

  if (v.event2PaymentAmount) {
    paymentAmount2 = v.event2PaymentAmount;
  } else if (v.event2paymentType) {
    paymentAmount2 = Number(NS_initial_fee) + Number(riskFees);
  }

  const history = useHistory();
  activity_number = activity_number.toString();
  return (
    <div onClick={() => history.push(`/finalRelease/${_id}`)} style={{ cursor: "pointer" }}>
      <div className="card mb-3">
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <label className="text-muted d-block">Agreement</label>
              <p>#{v.ApplicationTitle && v.ApplicationTitle ? v.ApplicationTitle : v._id}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">HNTB File#</label>
              <p>{v.HNTBFileNumber}</p>
            </div>
            <div className="col-md-2">
              <label className="text-muted d-block">Activity Number</label>
              {/* Activity 4 */}
              <p>{activity_number ? activity_number : ""}</p>
            </div>

            <div className="col-md-3">
              <label className="text-muted d-block">Application Type</label>
              <p>{formName}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Status</label>
              <p>{"Completed"}</p>
            </div>
          </div>
        </div>
        <div className="card-header bg-transparent">
          <div className="row align-items-center">
            <div className="col-md-3">
              <label className="text-muted d-block">Applicant</label>
              <p>{v?.applicantDetails && v?.applicantDetails?.consultantContact?.name}</p>
            </div>
            {roleName === "Approving Accountant" && (
              <div className="col-md-2">
                <label className="text-muted d-block">Payment1</label>
                <p>
                  <ol className="m-0 px-3">
                    <li className="mb-0">{paymentAmount1}</li>
                    <li className="mb-0">{paymentAmount2}</li>
                  </ol>
                </p>
              </div>
            )}
            <div className="col-md-3">
              <label className="text-muted d-block">Applicant’s Primary Contact</label>
              <p>{v.applicantDetails && v.applicantDetails?.facilityCompany?.name}</p>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <label className="text-muted d-block">Proposed work involves</label>
              <p>{applicationData["Proposed Work Involves"] || ""} </p>
            </div>
            <div className="col-md-4">
              <label className="text-muted d-block">Orientation of proposed pipeline installation:</label>
              <p>{applicationData["Orientation of proposed pipeline installation"] || ""}</p>
            </div>
            <div className="col-md-4">
              <label className="text-muted d-block">Installation is:</label>
              <p>{applicationData["Installation is"] || ""}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label className="text-muted d-block">Type of proposed installation:</label>
              <p>{applicationData["Type of proposed installation"] || ""}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Total aerial length on the NCRR Corridor:</label>
              <p>{applicationData["Total aerial length on the NCRR Corridore"] || ""}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Total buried length on the NCRR Corridor:</label>
              <p>{applicationData["Total buried length on the NCRR Corridor"]}</p>
            </div>
            <div className="col-md-3">
              <label className="text-muted d-block">Name of contractor:</label>
              <p>{applicationData["Name of contractor"] || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
