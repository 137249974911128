import ApplicationPaymentsLoading from "../components/loaders/ApplicationPaymentsLoading";
import CompiledPaymentComponent from "../components/compiledPayment/CompiledPayment";
import { useApplication } from "../hooks/useApplication";
import { useUser } from "../hooks/useUser";

const Page = (props) => {
  const application = useApplication();
  const user = useUser();

  if (!application || !user) {
    return <ApplicationPaymentsLoading />;
  }

  return <CompiledPaymentComponent {...props} />;
};

export default Page;
