import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import "./SingleFileUpload.css";
import { useParams } from "react-router";
import checkFileName from "../../utils/checkSpecialCharacter";

export const SingleFileUpload = ({ label = "", name = "", register, errors, fileTypes = "", linkAction = "", linkContext = "", subLabel = "", additionalcomments = "", requiredLable = false }) => {
  const [fileList, setFileList] = useState([]);
  const [characterError, setCharacterError] = useState(false);

  const params = useParams();

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem(params.applicationID)) || {};
    if (savedData[`${name}filedisplay`]) {
      setFileList(savedData[`${name}filedisplay`]);
    }
  }, [params.applicationID, name]);

  const handleFileChange = (info) => {
    let updatedFileList = [...info.fileList];

    if (info.file.status === "removed") {
      updatedFileList = [];
    }

    // Ensure that files are properly stored in localStorage
    const filesToStore = updatedFileList.map((file) => ({
      ...file,
      originFileObj: undefined, // Remove the original file object to prevent serialization issues
    }));

    const savedData = JSON.parse(localStorage.getItem(params.applicationID)) || {};
    savedData[name] = updatedFileList;
    savedData[`${name}filedisplay`] = filesToStore;

    localStorage.setItem(params.applicationID, JSON.stringify(savedData));

    // Validate file name
    const isValid = updatedFileList.every((file) => checkFileName(file.name));
    setCharacterError(!isValid);

    if (isValid) {
      setFileList(updatedFileList);
      register(name, { value: updatedFileList.map((file) => file.originFileObj), required: updatedFileList.length === 0 });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <label className="form-label">
        {label}
        {requiredLable && <small className="text-danger ms-1">*</small>}
      </label>
      {linkContext && (
        <a href={linkAction} target="_blank" rel="noopener noreferrer">
          {linkContext}
        </a>
      )}
      {subLabel && <label className="form-label">{subLabel}</label>}
      <Upload
        id="single_file_upload"
        customRequest={dummyRequest}
        accept=".pdf, .cad"
        onChange={handleFileChange}
        fileList={fileList}
        showUploadList={{ showRemoveIcon: true }}
        maxCount={1}
        className="upload-list-inline"
      >
        <div className="input-group mb-3 single-fileupload">
          <div className="fileupload">
            <label htmlFor="files">Click to choose a single file</label>
          </div>
          {fileTypes && (
            <span className="input-group-text bg-secondary text-dark" id="basic-addon2">
              .pdf or .cad up to 100 MB
            </span>
          )}
          <span className="text-danger">
            {errors[name] && <span>Select a valid file for {label}</span>}
            {characterError && <span className="text-sm">Remove special characters from file name, allowed special characters are - and _</span>}
          </span>
        </div>
        {additionalcomments && <div className="form-text mb-3">{additionalcomments}</div>}
      </Upload>
    </>
  );
};
