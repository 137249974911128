import React, { useState } from "react";
import { login, sendotp } from "../../api/auth";
import { useHistory, useLocation } from "react-router-dom";
import { useAtoms } from "../../hooks/useAtoms";
import { setMyRole } from "../../utils/setMyRole";

const setError = (error = "") => {
  document.getElementById("success").innerText = "";
  document.getElementById("err").innerText = error;
};

const setSuccess = (success = "") => {
  document.getElementById("err").innerText = "";
  document.getElementById("success").innerText = success;
};

export const VerifyForm = (props) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otploading, setOtpLoading] = useState(false);
  const { setNavigationContent } = useAtoms();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  setNavigationContent(false);

  const email = props?.location?.props?.email || localStorage.getItem("userEmail");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(email, otp)
      .then((res) => {
        if (res.data.status) {
          setMyRole(res.data.data);
          if (res?.data?.data?.roleType === "CONSULTANT") {
            if (res?.data?.data?.facilityId) {
              localStorage.setItem("facilityId", "ALL");
            }
          }

          if (res?.data?.data?.roleType === "FACILITY") {
            localStorage.setItem("facilityId", res?.data?.data?.companyId);
          }

          localStorage.removeItem("userEmail");
          localStorage.setItem("token", res.data.accessToken);
          if (res.data.redirect !== "/dashboard") {
            history.push(res.data.redirect);
            return;
          }

          const redirectParam = searchParams.get("redirect");
          history.push(redirectParam || "/dashboard");
        } else {
          setError("Invalid Otp.  Please try again.");
        }
      })
      .catch((error) => {
        console.log(error);
        setError("Some problem occured. Please try again after some time.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendOtp = () => {
    setOtpLoading(true);
    sendotp(email)
      .then((res) => {
        if (res.data.status) {
          setSuccess(`Otp has been Send to ${email}`);
        } else {
          setError("Some problem occured. Please try again after some time.");
        }
      })
      .catch((error) => {
        console.log(error);
        setError("Some problem occured. Please try again after some time.");
      })
      .finally(() => {
        setOtpLoading(false);
      });
  };

  return (
    <>
      <form className="mt-4 text-start" onSubmit={handleSubmit}>
        <div className="p-2 alert">
          <div className="d-flex align-items-start justify-content-between">
            <p className="mb-0">A secret code was sent to {email}</p>
            <button className="btn btn-outline-dark" type="button" onClick={resendOtp} disabled={otploading}>
              {otploading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                  sending...
                </>
              ) : (
                "Resend"
              )}
            </button>
          </div>
        </div>
        <div className="mb-3">
          <label htmlfor="name" className="form-label">
            Enter the secret code to verify
          </label>
          <div className="input-group">
            <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={handleChange} />
            <button className="btn px-4 btn-dark" id="basic-addon2" type="submit" disabled={loading || otp.length < 5}>
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm me-2" role="status" aria-hidden="true"></span>
                  submitting...
                </>
              ) : (
                "Verify Secret code"
              )}
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <span
            id="err"
            style={{
              color: "red",
              fontSize: "12px",
              fontWeight: "700",
            }}
            className="invalid-feedback w-auto"
          ></span>
          <span
            id="success"
            style={{
              color: "green",
              fontSize: "12px",
              fontWeight: "700",
            }}
            className="invalid-feedback w-auto"
          ></span>
        </div>
      </form>
    </>
  );
};
