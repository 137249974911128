import { REACT_APP_NS_initial_fee_original, REACT_APP_NS_risk_finance_fee_original } from "../api";
import { getFees } from "../api/Netsuite";

export const getApplicationPaymentFee = async (application) => {
  if (!application) {
    return null;
  }
  let applicationFee = 1500;
  let engineeringFee = 2200;
  let transactionFee = 111;
  let applicationFeeWithTransactionFee = 1545;
  let engineeringFeeWithTransactionFee = 2266;
  let totalAmountForStripe = applicationFee + engineeringFee + transactionFee;
  let totalAmountForOfflinePayment = applicationFee + engineeringFee;

  const { event1PaymentAmount, event1applicationFee, event1engineeringFee, event1transactionFee = "121.9", event1paymentOn, applicationStage } = application;

  let submittedDate;

  if (event1paymentOn) {
    submittedDate = new Date(event1paymentOn);
  }

  if (!event1paymentOn) {
    if (applicationStage < 6 || applicationStage === 26) {
      submittedDate = new Date();
    }

    if (applicationStage >= 6 && applicationStage !== 26) {
      submittedDate = new Date(2023, 5, 6);
    }
  }

  if (event1PaymentAmount && event1applicationFee && event1engineeringFee && event1transactionFee) {
    return {
      applicationFee: event1applicationFee,
      engineeringFee: event1engineeringFee,
      transactionFee: event1transactionFee,
      applicationFeeWithTransactionFee: Math.round(event1applicationFee * 1.0301),
      engineeringFeeWithTransactionFee: Math.round(event1engineeringFee * 1.0301),
      submittedDate,
      totalAmountForStripe: event1applicationFee + event1engineeringFee + event1transactionFee,
      totalAmountForOfflinePayment: event1applicationFee + event1engineeringFee,
    };
  }

  if (submittedDate.getFullYear() >= 2024) {
    const res = await getFees();
    if (res?.data?.status === "Success") {
      const { fee_hntb_engineering_review_fee, fee_ncrr_application_fee } = res.data.data;
      applicationFee = fee_ncrr_application_fee;
      engineeringFee = fee_hntb_engineering_review_fee;
      transactionFee = ((fee_ncrr_application_fee + fee_hntb_engineering_review_fee) * 0.0301).toFixed(1);
      applicationFeeWithTransactionFee = Math.round(fee_ncrr_application_fee * 1.0301);
      engineeringFeeWithTransactionFee = Math.round(fee_hntb_engineering_review_fee * 1.0301);
      totalAmountForStripe = applicationFee + engineeringFee + Number(transactionFee);
      totalAmountForOfflinePayment = applicationFee + engineeringFee;
    } else {
      applicationFee = 1700;
      engineeringFee = 2350;
      transactionFee = 121.9;
      applicationFeeWithTransactionFee = 1751.17;
      engineeringFeeWithTransactionFee = 2420.73;
      totalAmountForStripe = applicationFee + engineeringFee + transactionFee;
      totalAmountForOfflinePayment = applicationFee + engineeringFee;
    }
  }
  return {
    applicationFee,
    engineeringFee,
    transactionFee,
    applicationFeeWithTransactionFee,
    engineeringFeeWithTransactionFee,
    submittedDate,
    totalAmountForStripe,
    totalAmountForOfflinePayment,
  };
};

export const getCompilePaymentFee = (application) => {
  const { agreement, payment, event2paymentType } = application || {};
  const { draft = [], compiled = [] } = agreement || {};
  const { event2StripeResponse = [] } = payment || {};

  const license_fee_draft = draft.find((each) => each.action === "Upload and Send to NCRR" && each.role === "Engineering Representative");
  const risk_finance_fee_compiled = compiled.find((each) => each.action === "Sign and Paid" && each.role === "applicant");

  const license_fee = license_fee_draft?.licenseFee[0]?.license_fee || 0;
  const risk_fee = risk_finance_fee_compiled?.riskFinanceFee === "true";

  const nsr_application_fee = Number(REACT_APP_NS_initial_fee_original);

  const onlinePayment = event2StripeResponse.find((each) => each?.payment_status === "paid") ? true : event2paymentType === "Stripe" ? true : false;

  let totalPaymentFee = Number(nsr_application_fee) + Number(license_fee);
  let riskFinanceFee = 0;

  if (risk_fee) {
    riskFinanceFee += Number(REACT_APP_NS_risk_finance_fee_original);
    totalPaymentFee += Number(riskFinanceFee);
  }

  const initial_fee_transaction_fee = 6;
  const risk_finance_fee_transaction_fee = 57;
  const license_fee_transaction_fee = Math.round(license_fee * 0.0301);
  const transactionFee = initial_fee_transaction_fee + risk_finance_fee_transaction_fee + license_fee_transaction_fee;

  if (onlinePayment) {
    totalPaymentFee += Number(transactionFee);
    totalPaymentFee = totalPaymentFee.toFixed(2);
  }

  return { license_fee, riskFinanceFee, nsr_application_fee, totalPaymentFee, transactionFee };
};
