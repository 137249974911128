import React from "react";
import { useApplication } from "../../hooks/useApplication";
import { getCompilePaymentFee } from "../../utils/getPaymentAmount";

export const PaymentInformation = () => {
  const application = useApplication();
  const { ApplicationTitle, formNumber, applicantDetails, payment, event2paymentType } = application || {};
  const { primaryContact, billingContact } = applicantDetails || {};
  const { event2StripeResponse = [] } = payment || {};

  const onlinePayment = event2StripeResponse.find((each) => each?.payment_status === "paid") ? true : event2paymentType === "Stripe" ? true : false;

  const { license_fee, riskFinanceFee, nsr_application_fee, totalPaymentFee, transactionFee } = getCompilePaymentFee(application);

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item mb-4">
        <h2 className="accordion-header" id="PaymentInformation">
          <button
            className=" accordion-button py-4 px-4 d-flex justify-content-between "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#PaymentCollapse"
            aria-expanded="false"
            aria-controls="PaymentCollapse"
          >
            Payment Information
          </button>
        </h2>
        <div id="PaymentCollapse" className="accordion-collapse show" aria-labelledby="PaymentInformation" data-bs-parent="#accordionExample">
          <div className="accordion-body pt-0">
            <div className="card-col">
              <div className="card-body px-0">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Application Information</h3>
                      <p>
                        Application number: <strong>#{ApplicationTitle}</strong>
                        <br />
                        Form type: <strong>{formNumber}</strong>
                        <br />
                        HNTB File Number: <strong>{application?.HNTBFileNumber}</strong>
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-col"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Primary Contact</h3>
                      {primaryContact && (
                        <p>
                          <strong className="d-block mb-2">{primaryContact?.jobTitle + " " + primaryContact?.name}</strong>
                          {primaryContact?.name}
                          <br />
                          <br />
                          {primaryContact?.address?.address}
                          <br />
                          {primaryContact?.address?.city},{primaryContact?.address?.state}
                          <br />
                          {primaryContact?.email}
                          <br />
                          {primaryContact?.address?.zip}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-col">
                      <h3>Billing Contact</h3>
                      {billingContact && (
                        <p>
                          <strong className="d-block mb-2">{billingContact?.jobTitle + " " + billingContact?.name}</strong> {billingContact?.email}
                          <br />
                          <br />
                          {billingContact?.address?.address}
                          <br />
                          {billingContact?.address?.city},{billingContact?.address?.state}
                          <br />
                          {billingContact?.phone}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item-col mt-5">
                  <div className="heading py-2 border-bottom d-none d-sm-block">
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="text-muted">Item</label>
                      </div>
                      <div className="col-sm-3">
                        <label className="text-muted">Qty</label>
                      </div>

                      <div className="col-sm-3">
                        <label className="text-muted">Amount</label>
                      </div>
                    </div>
                  </div>
                  {
                    <>
                      <div className="detail">
                        <div className="row py-2">
                          <div className="col-sm-6">
                            <label className="d-block d-sm-none text-muted">Item</label>
                            <span className="text">NCRR License Fee</span>
                          </div>
                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Qty</label>
                            <span className="text">1</span>
                          </div>

                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Total</label>
                            <span className="text">$ {license_fee}</span>
                          </div>
                        </div>
                      </div>

                      <div className="detail">
                        <div className="row py-2">
                          <div className="col-sm-6">
                            <label className="d-block d-sm-none text-muted">Item</label>
                            <span className="text">NSR Application Fee</span>
                          </div>
                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Qty</label>
                            <span className="text">1</span>
                          </div>

                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Total</label>
                            <span className="text">$ {nsr_application_fee}</span>
                          </div>
                        </div>
                      </div>

                      {riskFinanceFee !== 0 && (
                        <div className="detail">
                          <div className="row py-2">
                            <div className="col-sm-6">
                              <label className="d-block d-sm-none text-muted">Item</label>
                              <span className="text">NSR Risk Finance Fee</span>
                            </div>
                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Qty</label>
                              <span className="text">1</span>
                            </div>

                            <div className="col-sm-3">
                              <label className="d-block d-sm-none text-muted">Total</label>
                              <span className="text">$ {riskFinanceFee}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  }
                  <hr className="m-0 p-0" />

                  {onlinePayment && (
                    <>
                      <div className="detail">
                        <div className="row py-2">
                          <div className="col-sm-6">
                            <label className="d-block d-sm-none text-muted">Item</label>
                            <span className="text-muted">Transaction Fee</span>
                          </div>
                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Qty</label>
                          </div>

                          <div className="col-sm-3">
                            <label className="d-block d-sm-none text-muted">Total</label>
                            <span className="text">${transactionFee}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <hr className="mt-0 pt-0 mb-3" />

                  <div className="total">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <span className="text text-muted">Total</span>
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            <span className="text">${totalPaymentFee}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {(event2paymentType === "Check" || event2paymentType === "ACH") && (
                    <>
                      <hr />
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <button className="btn btn-outline-primary btn-lg w-100" onClick={() => window.print()}>
                            Print
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
