/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getLicenseStatus, getCommentsDraftAgreement, getcurrentStatus, getFormData, draftAgreementPost, deleteSupportingFile, sendNotify, resolveDraftLicense } from "../../api/currentApplications";
import { API_URL, File, REACT_APP_URL } from "../../api";
import DraftCommentComments from "./DraftCommentComments";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

export default function ReviewDraftAgreement(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let { ItemId } = props;
  const [draftValue, setDraftValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [allComments, setAllComments] = useState([]);
  const [resolved, setResolved] = useState(false);
  const [stage, setStage] = useState("");
  const [showInputTag, setShowInputTag] = useState(false);
  const [notification, setNotification] = useState({});
  const [singlehntb, setSingleHntb] = useState(false);
  const [singleApprover, setSingleApprover] = useState(false);
  const [applicationsignedDocumentsEnvelopeId, setapplicationsignedDocumentsEnvelopeId] = useState("");
  const [editedFile, setEditedFile] = useState({});
  const [agreementData, setAgreementData] = useState(false);
  const [supporting, setSupporting] = useState({});

  useEffect(async () => {
    getFormData(ItemId).then(({ data }) => {
      if (data && data.signedDocumentsEnvelopeId) {
        setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
      }
    });
    let formData = await getFormData(props.ItemId);
    if (formData.data.applicationData && formData.data.applicationData) {
      localStorage.setItem(props.ItemId, JSON.stringify(formData.data.applicationData));
    }
  }, []);

  useEffect(async () => {
    let response = await getLicenseStatus(ItemId);
    setDraftValue(response.data.data);
    let SupportingDoc = response.data.data.draft[0].supportingData[0].fileDataMultiple;
    setSupporting(SupportingDoc);
    setLoading(false);
  }, []);
  useEffect(async () => {
    let newRes = await getcurrentStatus(ItemId);
    setStage(newRes.data.data);
  }, []);

  const { roleName = "", contact = {}, id: contactRoleId } = useUser() || {};
  let draft = draftValue.draft || {};
  let draftdata = draft && draft[0];

  let { licenseFee, AgreementData, supportingData } = draftdata || {};
  let { action, date } = draftdata || {};
  let license_value = licenseFee && licenseFee[0];
  let supporting_doc_value = (supportingData && supportingData[0]) || {};

  let { license_fee } = license_value || "";
  let { fileNameOriginal, fileNameUpdated } = (AgreementData && AgreementData[0]) || {};
  const onSubmit = () => {
    props.reviewDraftAgreement();
  };
  const onEditFormSubmit = (data) => saveLicFee(data);

  useEffect(async () => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      setAllComments((prev) => (prev ? [...prev, msg] : [msg] || []));
    });
    let responseComment = await getCommentsDraftAgreement(ItemId);

    await responseComment.data.data.draft.map((value) => (value.action === "Upload and Send to NCRR" ? setAllComments(value.comment ? value.comment : []) : null));

    return () => {
      setAllComments([]);
    };
  }, []);

  const deleteItem = () => {
    setAgreementData(true);
  };

  const deleteSupporting = async (e, name) => {
    e.preventDefault();
    await deleteSupportingFile({
      applicationID: ItemId,
      fileNameOriginal: name,
    });
    setSupporting(supporting.filter((item) => item.fileNameOriginal !== name));
  };

  const showInput = () => {
    setShowInputTag(true);
  };
  const saveLicFee = async (data) => {
    if (document.getElementById("aggrement1") !== null) {
      const license_file = document.getElementById("aggrement1").files[0];
      var filename = license_file ? license_file.name : null;
      const licenseFee = document.getElementById("license_fee").value;

      const formData = new FormData();
      formData.append("action", "Upload and Send to NCRR");
      formData.append("applicationID", ItemId);
      formData.append("role", roleName);
      formData.append("date", new Date());
      if (filename) {
        formData.append("licensefile", license_file, filename);
      }
      formData.append("update", true);
      let multiFile = data["supporting_doc"];
      let supportingdocFilesData = [];
      if (multiFile) {
        for (let i = 0; i < multiFile.length; i++) {
          let fileData = multiFile[i];
          if (fileData) {
            formData.append("supporting_doc_files", fileData);
            supportingdocFilesData.push({
              fileNameOriginal: fileData.name,
              fileName: "",
              fileNameUpdated: "",
            });
          }
        }
      }

      let AgreementData = [
        {
          fileLabel: "aggrement",
          fileNameOriginal: filename,
          fileName: "",
        },
      ];
      let license_fee = [
        {
          license_fee: licenseFee,
        },
      ];
      let supportingData = [
        {
          fileLabel: "supportingdoc",
          fileDataMultiple: supportingdocFilesData,
          fileName: "",
        },
      ];

      formData.append("AgreementData", JSON.stringify(AgreementData));
      formData.append("licenseFee", JSON.stringify(license_fee));
      formData.append("supportingData", JSON.stringify(supportingData));
      formData.append("type", "edit");
      await draftAgreementPost(formData);
      window.location.reload();
      setShowInputTag(false);
    }
  };
  const sendNotification = async (commentType, notificationTo) => {
    setNotification({});
    let data = {
      applicationID: ItemId,
      notificationTo: notificationTo,
      custID: contactRoleId,
    };

    let datas = await sendNotify(data);
    setNotification(notificationTo);
    setSingleHntb(false);
    setSingleApprover(false);
  };
  useEffect(async () => {
    getFormData(ItemId).then(({ data }) => {
      // setApplicationTitle(data.ApplicationTitle && data.ApplicationTitle);
      if (data && data.signedDocumentsEnvelopeId) {
        setapplicationsignedDocumentsEnvelopeId(data.signedDocumentsEnvelopeId);
      }
    });
    const object = {
      applicationID: ItemId,
      signerEmail: ["Test"],
    };
    let response = await sendNotify(object);
    setCompileValue(response.data.responseData);
  }, []);
  const resolveComment = async () => {
    try {
      const data = {
        applicationID: ItemId,
        resolved: true,
      };
      const res = await resolveDraftLicense(data);
      if (res.data.responseData) {
        window.location.reload();
      }
    } catch (error) {
      // toast.error(error.message);
      alert(error.message);
    }
  };
  useEffect(() => {
    let license_object;
    try {
      license_object = draftValue.draft.find((each) => each.action === "Upload and Send to NCRR");
    } catch (error) {
      license_object = {};
    }
    setResolved(license_object?.resolved === true ? true : allComments.length > 0 ? false : true || false);
  }, [allComments]);
  const [compileValue, setCompileValue] = useState({});
  let currentEmail = contact.email;
  const SignedDate = compileValue ? (compileValue.signedAt ? new Date(compileValue.signedAt).toString().slice(0, 15) : "") : "";
  let { name: SignerName, email: SignerEmail } = compileValue || {};
  const validateSignedDate = compileValue ? (compileValue.validatedat ? new Date(compileValue.validatedat).toString().slice(0, 15) : "") : "";
  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div id="files-upload" className="">
              <div className="card mb-4 position-relative comment-card">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Sign Application</h2>
                    <small className="text-muted">By Applicant</small>
                  </div>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <div className="mb-2 mt-2">
                      {SignerEmail === currentEmail ? (
                        <label className="text-muted d-block form-label d-block">You are authorized to sign the application.</label>
                      ) : (
                        <label className="text-muted d-block form-label d-block">{"Application Signed"}</label>
                      )}

                      {SignerName && (
                        <>
                          <div className="alert-success alert fs-5 mt-3 mb-0 text-center">{`${SignerName} digitally Signed this Application.`}</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Signed
                      </span>
                      <span>
                        By {SignerName} on <small> {SignedDate ? SignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="card mb-4 shadow ">
                <div className="card-header  border-bottom">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="mb-0">Validate Application Signature</h2>
                    <small className="text-muted">For HNTB</small>
                  </div>
                </div>
                <div className="card-body pt-0 pb-0">
                  <div className="card bg-secondary border-0">
                    <div className="card-body p-3">
                      <div>
                        <span className="mb-0 text-muted">Application</span>
                        <a target="_blank" href={API_URL + File + (applicationsignedDocumentsEnvelopeId ? "1_" : "") + ItemId + ".pdf"} className="btn btn-link">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer py-0 justify-content-center bg-success">
                  <a className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                    <span className="d-flex justify-content-between align-items-center">
                      <span>
                        <i className="fal fa-check"></i> Validated
                      </span>
                      <span>
                        By HNTB on <small>{validateSignedDate ? validateSignedDate : ""}</small>
                      </span>
                    </span>
                  </a>
                </div>
              </div>
              <form onSubmit={handleSubmit(onEditFormSubmit)}>
                <div className="card mb-4 position-relative comment-card">
                  {roleName == "Engineering Representative" && allComments && allComments.length > 0 && (
                    <>
                      {notification == "approvingRepresentatives" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success  m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singleApprover === true ? (
                            <>
                              <div className="position-absolute notifyBtn">
                                <a className="btn btn-outline-success  m-0 disabled">
                                  <small>
                                    <i className="far fa-check"></i> Notification Sent
                                  </small>
                                </a>
                              </div>
                            </>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("engineeringRepresentatives", "approvingRepresentatives", setSingleApprover(true))} className="btn btn-primary text-white m-0">
                                <small>Notify Approvers</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {roleName == "Approving Representative" && allComments && allComments.length > 0 && (
                    <>
                      {notification == "engineeringRepresentatives" ? (
                        <div className="position-absolute notifyBtn">
                          <a className="btn btn-success  m-0 disabled">
                            <small>
                              <i className="fas fa-check-double"></i> Notification Sent
                            </small>
                          </a>
                        </div>
                      ) : (
                        <>
                          {singlehntb === true ? (
                            <div className="position-absolute notifyBtn">
                              <a className="btn btn-outline-success  m-0 disabled">
                                <small>
                                  <i className="far fa-check"></i> Notification Sent
                                </small>
                              </a>
                            </div>
                          ) : (
                            <div className="position-absolute notifyBtn">
                              <a onClick={() => sendNotification("approvingRepresentatives", "engineeringRepresentatives")} className="btn btn-primary text-white m-0">
                                <small>Notify HNTB</small>
                              </a>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">Agreement Generated</h2>
                      <small className="text-muted">By HNTB</small>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <label className="text-muted d-block">Uploaded Agreement</label>
                        {(roleName === "Engineering Representative" && allComments && allComments.length > 0) || roleName === "Approving Representative" ? (
                          <button type="button" className="btn-outline-dark btn-sm btn text-dark comment-btn position-relative" data-bs-toggle="modal" data-bs-target="#comment-modal">
                            {stage.currentStep >= 12 ? "View" : <>{allComments && allComments.length > 0 ? "Comments" : " Start New Comment"}</>}
                          </button>
                        ) : null}
                      </div>

                      <DraftCommentComments
                        stage={stage.currentStep}
                        license_fee={license_fee}
                        fileNameOriginal={fileNameOriginal}
                        fileNameUpdated={fileNameUpdated}
                        supporting_doc_value={supporting_doc_value}
                        resolved={resolved}
                        resolveComment={resolveComment}
                      />
                      <div className="card bg-secondary border-0">
                        <div className="card-body p-3">
                          {showInputTag ? (
                            <>
                              {agreementData === true ? (
                                <>
                                  {" "}
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    id="aggrement1"
                                    // required={true}
                                    className="form-control "
                                    onChange={(e) => setEditedFile(e.target.files[0])}
                                    {...register("aggrement1", {
                                      required: true,
                                    })}
                                  />
                                  <span className="text-danger">
                                    <span>{errors.aggrement1 && errors.aggrement1.type === "required" && <span>This field is required</span>}</span>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <input accept=".pdf" type="file" id="aggrement1" className="form-control " onChange={(e) => setEditedFile(e.target.files[0])} />
                                  {fileNameOriginal ? (
                                    <div className="pl-3 mt-2">
                                      {fileNameOriginal}
                                      <button onClick={deleteItem} className="btn btn-link">
                                        <i style={{ color: "red" }} className="fa fa-trash" />
                                      </button>
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : (
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <i className="fal fa-file-pdf"></i>
                              </div>
                              <div className="flex-grow-1 ms-3 w-50">{fileNameOriginal}</div>
                              <div className="flex-shrink-0">
                                <a href={API_URL + File + fileNameUpdated} target="_blank" className="btn btn-link">
                                  View
                                </a>
                                <a href={API_URL + File + fileNameUpdated} target="_blank" className="btn btn-link">
                                  Download
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {roleName === "Engineering Representative" || roleName === "Approving Representative" ? (
                      <>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="text-muted d-block">Supporting Documents</label>
                          </div>

                          <div className="card bg-secondary border-0">
                            <form>
                              <div className="card-body p-3">
                                {showInputTag ? (
                                  <>
                                    <input
                                      accept=".pdf"
                                      type="file"
                                      name="supporting_doc"
                                      id="supporting_doc"
                                      className="form-control"
                                      multiple
                                      onChange={(e) => setEditedFile(e.target.files[0])}
                                      {...register("supporting_doc")}
                                    />
                                    {supporting.map((supporting_doc_value, id) => (
                                      <>
                                        {supporting_doc_value.fileNameOriginal ? (
                                          <div className="pl-3 mt-2" key={id}>
                                            {supporting_doc_value.fileNameOriginal}
                                            <button onClick={(e) => deleteSupporting(e, supporting_doc_value.fileNameOriginal)} className="btn btn-link">
                                              <i style={{ color: "red" }} className="fa fa-trash" />
                                            </button>
                                          </div>
                                        ) : null}
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {supporting_doc_value.fileDataMultiple && supporting_doc_value.fileDataMultiple.length > 0 ? (
                                      <>
                                        {supporting_doc_value.fileDataMultiple &&
                                          supporting_doc_value.fileDataMultiple.map((supporting_doc_value, ind) => (
                                            <div className="card-body p-0" key={ind}>
                                              <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                  <i className="fal fa-file-pdf"></i>
                                                </div>
                                                <div className="flex-grow-1 ms-3 w-50">{supporting_doc_value.fileNameOriginal ? supporting_doc_value.fileNameOriginal : fileNameOriginal}</div>
                                                <div className="flex-shrink-0">
                                                  <a
                                                    href={API_URL + File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)}
                                                    target="_blank"
                                                    className="btn btn-link"
                                                  >
                                                    View
                                                  </a>
                                                  <a
                                                    href={API_URL + File + (supporting_doc_value.fileNameUpdated ? supporting_doc_value.fileNameUpdated : null)}
                                                    target="_blank"
                                                    className="btn btn-link"
                                                  >
                                                    Download
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          ))}{" "}
                                      </>
                                    ) : (
                                      <p className="h5">No Supporting Documents</p>
                                    )}
                                  </>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="d-flex justify-content-between align-items-center ">
                            <label className="text-muted d-block">Entered License Fee</label>
                          </div>
                          <div className="card bg-secondary border-0">
                            {showInputTag ? (
                              <div className="card-body ">
                                <input
                                  className="form-control "
                                  defaultValue={license_fee}
                                  //onChange={(e) => setEditedLicFee(e.target.value)}
                                  name="license_fee"
                                  id="license_fee"
                                  {...register("license_fee", {
                                    required: true,
                                  })}
                                />
                                <span className="text-danger">
                                  <span>{errors.license_fee && <span>This field is required</span>}</span>
                                </span>
                              </div>
                            ) : (
                              <div className="card-body p-3">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">${license_fee}</div>
                                </div>
                              </div>
                            )}
                          </div>

                          <>
                            {roleName === "Engineering Representative" && !resolved && (
                              <>
                                {" "}
                                {!showInputTag && (
                                  <button
                                    className="btn btn-link"
                                    style={{
                                      textDecoration: "underline",
                                      pointerEvents: "fill",
                                    }}
                                    onClick={showInput}
                                  >
                                    <i className="far fa-pen"></i> Edit
                                  </button>
                                )}
                                {showInputTag && (
                                  <>
                                    <button
                                      className="btn btn-link"
                                      style={{
                                        textDecoration: "underline",
                                        pointerEvents: "fill",
                                      }}
                                    >
                                      <i className="far fa-check"></i> Save
                                    </button>
                                    <a
                                      className="btn btn-link text-warning"
                                      style={{
                                        textDecoration: "underline",
                                        pointerEvents: "fill",
                                      }}
                                      href={REACT_APP_URL + "/draftAgreement/" + ItemId}
                                    >
                                      <i className="far fa-ban"></i> Cancel
                                    </a>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="card-footer py-0 justify-content-center bg-success">
                    <a href="#" className="d-block text-white btn disabled py-4 px-0 border-0" tabIndex="-1" role="button" aria-disabled="true">
                      <span className="d-flex justify-content-between align-items-center">
                        <span>
                          <i className="fal fa-check"></i>
                          {action}
                        </span>
                        <span>
                          {""} By HNTB on <small> {date ? date.slice(0, 15) : ""}</small>
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </form>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card mb-4 border-primary shadow ">
                  <div className="card-header  border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                      <h2 className="mb-0">NCRR Review Draft Agreement</h2>
                      <small className="text-muted">For NCRR</small>
                    </div>
                  </div>
                  <>
                    {roleName !== "Approving Representative" ? (
                      <div className="card-footer justify-content-center " style={{ backgroundColor: "rgb(233 237 241)" }}>
                        Awaiting for NCRR
                        <i className="fal fa-chevron-right ms-2"></i>
                      </div>
                    ) : (
                      <div className={`card-footer justify-content-center bg-primary ${resolved ? "" : "disabled"}`}>
                        <button
                          disabled={roleName !== "Approving Representative" || !resolved}
                          style={{
                            border: "none",
                            backgroundColor: "#5E74E4",
                          }}
                          className="d-block text-white"
                        >
                          {roleName !== "Approving Representative" ? "Awaiting for NCRR" : "Send to Norfolk Southern"}
                          <i className="fal fa-chevron-right ms-2"></i>
                        </button>
                      </div>
                    )}
                  </>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
