import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import { getAssignedContacts, updateContactCompanydetails } from "../../api/crm";
import NewForm220 from "../application/NewForm220";
import AssignContacts from "../assignContacts/AssignContacts";
import { useUser, useUserRefetch } from "../../hooks/useUser";
import { FacilityCompanyList } from "../consultant/FacilityCompanyList";
import DashBoardLoading from "../loaders/DashBoardLoading";

export const Form220 = (props) => {
  const { applicationID } = useParams();
  const [loading, setLoading] = useState(false);
  const [checkContactsAssigned, setCheckContactsAssigned] = useState(false);
  const [companySelected, setCompanySelected] = useState(false);
  const [assignedContacts, setAssignedContacts] = useState({
    signatory: "",
    primaryRepresentative: "",
    billingRepresentative: "",
    engineeringConsultant: "",
    engineeringConsultantCompany: "",
  });
  const [companyContactApplication, setCompanyContactApplication] = useState({});
  const location = useLocation();

  // Parse the query parameters from the location object
  const queryParams = new URLSearchParams(location.search);
  const companyID = queryParams.get("companyID");

  const user = useUser();
  const refetchUser = useUserRefetch();

  const checkContacts = async (applicationID, user) => {
    try {
      setLoading(true);
      const { data } = await getAssignedContacts(applicationID);
      if (data.status) {
        if (data.data) {
          const { billingContact, primaryContact, consultantContact } = data.data;
          if (billingContact || primaryContact || consultantContact) {
            if (user?.role?.roleType === "FACILITY") {
              setCheckContactsAssigned(true);
            } else {
              if (billingContact || primaryContact) {
                setCheckContactsAssigned(true);
              }
            }
            setCompanyContactApplication(data.data);
          }
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkCompanySelected = async (user, companyContactApplication) => {
    if (user) {
      if (user.role.roleType === "CONSULTANT") {
        const { facilityCompany } = companyContactApplication;
        if (facilityCompany) {
          setCompanySelected(true);
        }
      }
    }
  };

  useEffect(() => {
    checkContacts(applicationID, user);
  }, [applicationID, user]);

  useEffect(() => {
    checkCompanySelected(user, companyContactApplication);
  }, [user, companyContactApplication]);

  useEffect(() => {
    if (companyID) {
      handleSaveFacilityId(companyID, applicationID, refetchUser);
    }
  }, [companyID, applicationID, refetchUser]);

  const handleSaveFacilityId = async (companyID, applicationID, refetchUser) => {
    const data = {
      applicationID,
      assignedContacts: {
        facilityCompany: companyID,
      },
    };
    await updateContactCompanydetails(data);
    setCompanySelected(true);
    setCheckContactsAssigned(false);
    refetchUser();
  };

  const handleSave = async () => {
    setCheckContactsAssigned(true);
    const data = {
      applicationID,
      assignedContacts,
    };
    await updateContactCompanydetails(data);
  };

  const handleSaveFacility = async (facility) => {
    const data = {
      applicationID,
      assignedContacts: {
        facilityCompany: facility.id,
      },
    };
    await updateContactCompanydetails(data);
    setCompanySelected(true);
    setCheckContactsAssigned(false);
  };

  if (loading || !user) {
    return <DashBoardLoading />;
  }

  if (user.role.roleType === "CONSULTANT") {
    if (!companySelected) {
      return <FacilityCompanyList user={user} onSave={handleSaveFacility} />;
    }
  }

  if (!checkContactsAssigned) {
    return <AssignContacts onSave={handleSave} setAssignedContacts={setAssignedContacts} companyContactApplication={companyContactApplication} />;
  }

  return <NewForm220 {...props} />;
};
