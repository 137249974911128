import React, { useEffect, useState } from "react";

import { HandleFieldType } from "../../forms/HandleFieldType";
import { addComments, getAdditionalQues, additionalQuesAnswered, approveOrReject, getcurrentStatus, resolveComments, unResolveComment } from "../../api/currentApplications";
import { API_URL, File } from "../../api";
import CommentsModal from "../commentsModal/CommentsModal";
import { FormOnAFly } from "../../forms/FormOnAFly";
import { useUser } from "../../hooks/useUser";
import { socket } from "../../utils/socket-io";

function ApplicantformFilledStatic(props) {
  const user = useUser() || {};
  const { roleName = "", contact = {} } = user;
  const [comment, setComment] = useState(" ");
  const [qId, setQid] = useState(0);
  const [editorState, setEditorState] = useState();
  const [active, setActive] = useState("applicant");
  const [file, setFile] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [approverComment, setApproverComment] = useState([]);
  const [teamComment, setTeamComment] = useState([]);
  const [resolvedComment, setResolvedComments] = useState([]);
  const [ID, setID] = useState();
  const [fileList, setFileList] = useState(true);

  const [additionalQuesSchema, setAdditionalQuesSchema] = useState(null);
  const [reason, setReason] = useState("");
  const [check, SetCheck] = useState("");
  const [modalClose, setModalClose] = useState(false);
  const [fileValue, setFileValue] = useState();
  // let ItemID = props.match.params.applicationID
  React.useEffect(async () => {
    let newRes = await getcurrentStatus(props.ItemId);
    SetCheck(newRes.data.data.currentStep);
  }, []);
  useEffect(() => {
    socket.emit("chat send");
    socket.on("chat send", (msg) => {
      if (msg.commentType == "team") {
        setTeamComment((prev) => [...prev, msg]);
      }
      if (msg.commentType == "applicant") {
        setAllComments((prev) => [...prev, msg]);
      }
      if (msg.commentType == "approver") {
        setApproverComment((prev) => [...prev, msg]);
      }
    });
    setAllComments(props.allComments.applicationComments.applicant);
    setApproverComment(props.allComments.applicationComments.approver);
    setTeamComment(props.allComments.applicationComments.team);
    setResolvedComments(props.resolvedComment);
    getAdditionalQuestions();
  }, []);

  function getAdditionalQuestions() {
    getAdditionalQues(props.applicationID)
      .then(({ data }) => {
        if (data.additionalQues && data.additionalQues.length > 0) setAdditionalQuesSchema(JSON.parse(data.additionalQues));
      })
      .catch((err) => console.log(err));
  }

  function handleAdditionalSubmit(data) {
    const d = new FormData();
    d.append("applicationID", props.applicationID);
    Object.keys(data).forEach((v) => {
      if (typeof data[v][0] == "object") {
        d.append(v, data[v][0]);
      } else d.append(v, data[v]);
    });
    additionalQuesAnswered(d)
      .then((data) => {
        if (data.status == 204) {
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  }
  function handleApplicationReject() {
    const data = {
      reason: reason,
      rejected: true,
      applicationID: props.applicationID,
    };
    approveOrReject(data)
      .then(({ data }) => {
        setModalClose(true);
        // toast.error("")
        alert("Application was Rejected");
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }
  function handleReason(e) {
    setReason(e.target.value);
  }
  const resolveCommet = async (qId) => {
    let data = {
      applicationID: props.applicationID,
      reviewType: "applicationComments",
      questionID: qId,
    };
    let res = await unResolveComment(data);
    if (res) {
      window.location.reload();
    }
  };
  const DeleteFun = (name, index) => {
    let data = [...file];
    data.splice(index, 1);
    setFile(data);
  };

  const addComment = async (e) => {
    e.preventDefault();
    if (comment.length && ID) {
      setEditorState(" ");
      setComment(" ");

      const date = new Date();
      const formData = {
        id: user.id,
        name: user.contact.name,
        role: user.role.role || user.roleName,
        email: user.contact.email,
        roleName: user.roleName,
      };
      let msgActive = active;
      const data = {
        questionID: ID,
        comment: comment,
        timeStamp: date.toDateString(),
        from: JSON.stringify(formData),
        applicationID: props.applicationID,
        files: file,
        reviewType: "applicationComments",
        commentType: msgActive,
      };
      socket.emit("chat message", data);
      let comm = await addComments(data);
      if (comm) {
        setEditorState("");
        setFileValue("");
        setFileList(false);
        setFile([]);
        setComment(" ");
      }
    }
    setEditorState("");
    setComment("");
  };
  function handleComment(comment, id) {
    setComment(comment);
    setID(id);
  }
  const commentsResolve = async (data) => {
    const date = new Date().toJSON().slice(0, 10);
    let value = {
      questionID: data.id,
      applicationID: props.applicationID,
      reviewType: "applicationComments",
      resolvedBy: contact.email,
    };
    socket.emit("chat message", value);
    let dat = await resolveComments(value);
    if (dat) {
      window.location.reload();
    }
  };

  let allCommentsId = allComments.map((aID) => aID.questionID);
  let approverCommentId = approverComment.map((aID) => aID.questionID);
  let teamCommentId = teamComment.map((tID) => tID.questionID);
  let allID = [...allCommentsId, ...approverCommentId, ...teamCommentId];
  let resolveCommentId = resolvedComment.map((rID) => rID.questionID);

  return (
    <>
      <div className="inner-form">
        {props.formSchema.sections.map((v, i) =>
          v.questions.length > 0 ? (
            <div className="accordion" id="accordionExample" key={i}>
              <div key={i} id={`${v.label.split(" ").join("_").split("'").join("_") + i + "i"}`} className="accordion-item mb-4">
                <div className="card">
                  <div className="card-body p-0">
                    <h2 className="accordion-header " id={v.label.split(" ").join("_").split("'").join("_") + i}>
                      <button
                        className={i === 0 ? "accordion-button py-4 px-5 d-flex justify-content-between" : "accordion-button py-4 px-5 d-flex justify-content-between collapsed"}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${v.label.split(" ").join("").split("'").join("_")}`}
                        aria-expanded={i === 0 ? true : false}
                        aria-controls={v.label.split(" ").join("").split("'").join("_")}
                      >
                        <span>{v.label}</span>
                      </button>
                    </h2>
                    <div
                      id={v.label.split(" ").join("").split("'").join("_")}
                      className={i === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse "}
                      aria-labelledby={v.label.split(" ").join("_").split("'").join("_") + i}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body p-0">
                        <div className="page-section">
                          {v.questions.map((d, ind) => (
                            <>
                              {d && d.hide && d.hide ? (
                                ""
                              ) : (
                                <div key={ind} className="form-col" id={`${d.id}-ques`}>
                                  <div className={d.header !== "" ? "card-body position-relative" : "d-none"}>
                                    <div>
                                      {d.header ? (
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                          <h3>{d.header}</h3>

                                          {resolveCommentId.includes(d.id) ? (
                                            <button
                                              className="btn-outline-success btn-sm btn text-success comment-btn position-relative "
                                              type="button"
                                              data-bs-toggle="modal"
                                              onClick={() => {
                                                setQid(d);
                                              }}
                                              data-bs-target="#ongoin-comment-modal"
                                            >
                                              <i className="far fa-check"></i> {check > 4 ? "View" : "Resolved"}
                                            </button>
                                          ) : check <= 4 ? (
                                            <>
                                              {" "}
                                              {!allID.includes(d.id) && roleName !== "applicant" ? (
                                                <>
                                                  {
                                                    // Applicant comment section
                                                    <button
                                                      className="btn-outline-dark btn-sm btn text-dark comment-btn position-relative"
                                                      type="button"
                                                      data-bs-toggle="modal"
                                                      onClick={() => {
                                                        setQid(d);
                                                      }}
                                                      data-bs-target="#ongoin-comment-modal"
                                                    >
                                                      Start Comment
                                                    </button>
                                                  }
                                                </>
                                              ) : null}
                                              {check <= 4 ? (
                                                <>
                                                  {allID.includes(d.id) && (
                                                    <button
                                                      className="btn-outline-dark btn-sm btn text-dark comment-btn position-relative"
                                                      type="button"
                                                      data-bs-toggle="modal"
                                                      onClick={() => {
                                                        setQid(d);
                                                      }}
                                                      data-bs-target="#ongoin-comment-modal"
                                                    >
                                                      Comments
                                                    </button>
                                                  )}{" "}
                                                </>
                                              ) : null}
                                            </>
                                          ) : null}
                                        </div>
                                      ) : null}
                                      {d.rows.map((v, i) => (
                                        <>
                                          <div key={i} className="row">
                                            {v.columns.map((x, i) =>
                                              props.applicationData ? (
                                                <div key={i} className={`${x.type == "file" ? "col-sm-12" : "col"}`}>
                                                  <div key={i + Math.floor(Math.random())}>
                                                    <div className="">
                                                      <p className="text">
                                                        {x.type === "number" || x.type === "radio" || x.type === "dropdown" || (x.type != "multiplefile" && x.type === "text") ? (
                                                          <>
                                                            <label className="text-muted ">{x.label}</label>
                                                            <p className="m-0">
                                                              {props.applicationData[x.name]}
                                                              &nbsp;&nbsp;
                                                              {props.applicationData[x.name1] === undefined ? " " : "+"}
                                                              &nbsp;
                                                              {props.applicationData[x.name1]}
                                                              &nbsp; &nbsp;
                                                              {props.applicationData[x.name1] === undefined ? " " : "Feet"}
                                                            </p>
                                                            {/* {x.linkContext && (
                                                              <a
                                                                href={
                                                                  x.linkAction
                                                                }
                                                                target="_blank"
                                                              >
                                                                {" "}
                                                                {x.linkContext}
                                                              </a>
                                                            )} */}
                                                          </>
                                                        ) : (
                                                          <div className="">
                                                            <div>
                                                              {x.title ? (
                                                                // <h3>{x.title}</h3>
                                                                <></>
                                                              ) : (
                                                                <>
                                                                  {x.type === "tabel" ? (
                                                                    <div>
                                                                      {d.label ? <h3>{d.label}</h3> : null}

                                                                      <div className="row d-none d-sm-flex">
                                                                        {x.heading.map((head, index) => (
                                                                          <div className={index === 2 ? "col-sm-6" : "col-sm-3"}>
                                                                            <label htmlFor="" className="text-muted  d-block ">
                                                                              {head}
                                                                            </label>
                                                                          </div>
                                                                        ))}
                                                                      </div>
                                                                      <div className="row">
                                                                        {x.rowValue.map((name) => (
                                                                          <>
                                                                            {props.applicationData[name.rowData[0].name] !== "undefined" &&
                                                                              props.applicationData[name.rowData[1].name] !== "undefined" && (
                                                                                <>
                                                                                  <div className="col-sm-3 mb-3">
                                                                                    <label htmlFor="" className="text-muted  d-block d-sm-none"></label>
                                                                                    <p>{name.label === "other" ? props.applicationData.other : name.label}</p>
                                                                                  </div>
                                                                                  <div className="col-sm-3 mb-3">
                                                                                    <label htmlFor="" className="text-muted  d-block d-sm-none"></label>
                                                                                    <p>{props.applicationData[name.rowData[0].name]}</p>
                                                                                  </div>
                                                                                  <div className="col-sm-6 mb-3">
                                                                                    <label htmlFor="" className="text-muted d-block d-sm-none"></label>
                                                                                    <p>{props.applicationData[name.rowData[1].name]}</p>
                                                                                  </div>
                                                                                </>
                                                                              )}
                                                                          </>
                                                                        ))}
                                                                      </div>
                                                                    </div>
                                                                  ) : null}
                                                                </>
                                                              )}
                                                              {(x.name !== "Installation is" &&
                                                                x.name !== "Type of proposed installation" &&
                                                                x.name !== "Aerial Facilities" &&
                                                                x.name !== "Underground Facilities" &&
                                                                x.type === "checkbox") ||
                                                              x.type === "checkbox" ||
                                                              x.type === "checkboxGroup" ? (
                                                                <div>
                                                                  {x.options.map((check) => (
                                                                    <div className="form-check" style={check.styleInner}>
                                                                      <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        disabled={true}
                                                                        checked={props.applicationData[x.name] && props.applicationData[x.name].includes(check.value)}
                                                                      />
                                                                      <label className="form-check-label">{check.label}</label>
                                                                      {check.linkContext && (
                                                                        <a className="form-check-label" href={check.linkAction} target="_blank">
                                                                          {" "}
                                                                          {check.linkContext}
                                                                        </a>
                                                                      )}
                                                                      {check.subLabel && (
                                                                        <label className="mx-2 my-1 form-check-label" htmlFor={check.value}>
                                                                          {check.subLabel}
                                                                        </label>
                                                                      )}
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                              ) : (
                                                                <>
                                                                  {props.applicationData[x.name] === "Aerial Facilities" ||
                                                                  props.applicationData[x.name] === "Underground Facilities" ||
                                                                  props.applicationData[x.name] === "Type of proposed installation" ? (
                                                                    ""
                                                                  ) : Array.isArray(props.applicationData[x.name + "filedisplay"]) ? (
                                                                    <div className="card bg-secondary border-0">
                                                                      <div className="card-body p-3">
                                                                        {props.applicationData[x.name + "filedisplay"].map((file) => (
                                                                          <div className="d-flex align-items-center border-bottom file-download">
                                                                            <div className="flex-shrink-0">
                                                                              <i className="fal fa-file-pdf"></i>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">{file.name}</div>
                                                                            <div className="flex-shrink-0">
                                                                              <a href={API_URL + File + props.applicationID + "_" + file.name} target="_blank" className="btn btn-link">
                                                                                View
                                                                              </a>
                                                                              <a href={API_URL + File + props.applicationID + "_" + file.name} download target="_blank" className="btn btn-link">
                                                                                Download
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        ))}
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      {
                                                                        x.fieldType && "MP"
                                                                        //  ||
                                                                        //   ///////ops.applicationData[x.value] ===  "H-Line" ) ? x.fieldType && "MP H-"
                                                                        // : ""
                                                                      }

                                                                      {/* &nbsp; */}
                                                                      {props.applicationData[x.name]}
                                                                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                                      {x.fieldLabel}

                                                                      {props.applicationData[x.name1]}
                                                                      {/* &nbsp; */}
                                                                      {x.fieldType && " Feet"}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  {x.type === "tabel" ? (
                                                    <div>
                                                      {d.label ? <h3>{d.label}</h3> : null}

                                                      <div className="row d-none d-sm-flex">
                                                        {x.heading.map((head, index) => (
                                                          <div className={index === 2 ? "col-sm-6" : "col-sm-3"}>
                                                            <label htmlFor="" className="text-muted  d-block ">
                                                              {head}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </div>
                                                      <div className="row">
                                                        {x.rowValue.map((name) => (
                                                          <>
                                                            {props.applicationData[name.rowData[0].name] !== "undefined" && props.applicationData[name.rowData[1].name] !== "undefined" && (
                                                              <>
                                                                <div className="col-sm-3 mb-3">
                                                                  <label htmlFor="" className="text-muted  d-block d-sm-none"></label>
                                                                  <p>{name.label === "other" ? props.applicationData.other : name.label}</p>
                                                                </div>
                                                                <div className="col-sm-3 mb-3">
                                                                  <label htmlFor="" className="text-muted  d-block d-sm-none"></label>
                                                                  <p>{props.applicationData[name.rowData[0].name]}</p>
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                  <label htmlFor="" className="text-muted d-block d-sm-none"></label>
                                                                  <p>{props.applicationData[name.rowData[1].name]}</p>
                                                                </div>
                                                              </>
                                                            )}
                                                          </>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ),
                                            )}
                                          </div>
                                        </>
                                      ))}

                                      <>
                                        {d.casing == "casing" ? (
                                          <div className={d.casing == "casing" && d.header != "" ? `card border-dark ${d.header === "" ? "" : ""} border-bottom` : ""}>
                                            <div className={d.casing == "casing" && d.header != "" ? "card-body" : ""}>
                                              <HandleFieldType items={d} review={true} />
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null,
        )}
        <CommentsModal
          formSchema={props.formSchema}
          qId={qId}
          blockUnresolved={check > 4}
          commentTypes={"Application Review"}
          applicationData={props.applicationData}
          addComment={addComment}
          editorState={editorState}
          active={active}
          applicationID={props.ItemId}
          setActive={setActive}
          handleComment={handleComment}
          allComments={allComments}
          approverComment={approverComment}
          teamComment={teamComment}
          setFile={setFile}
          fileList={fileList}
          setFileList={setFileList}
          file={file}
          fileValue={fileValue}
          DeleteFun={DeleteFun}
          commentsResolve={commentsResolve}
          resolvedComment={resolvedComment}
          resolveCommet={resolveCommet}
        />
        {additionalQuesSchema && roleName == "applicant" ? (
          <div className="additional-questions">
            <FormOnAFly onSubmit={handleAdditionalSubmit}>{additionalQuesSchema}</FormOnAFly>
          </div>
        ) : null}

        <div className="modal fade" id="reject" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="resolve1">
                  Reason of Rejection
                </h2>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="position-relative w-100 border">
                  <textarea className="form-control comment-box w-100" rows="4" aria-label="With textarea" onChange={handleReason}></textarea>
                  <div className="text-option bg-secondary w-100 py-2">
                    <div className="d-flex justify-content-between">
                      <ul className="d-flex no-list mb-0">
                        <li>
                          <a className="text-muted" href="#">
                            <i className="fas fa-bold"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            <i className="fas fa-italic"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            <i className="fas fa-strikethrough"></i>
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            <i className="fas fa-underline"></i>
                          </a>
                        </li>
                      </ul>
                      {/* <!--<div className="send">
                                <a className="text-muted" href="#"><i className="fas fa-paper-plane"></i></a>
                            </div>--> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleApplicationReject} data-bs-dismiss={modalClose && "modal"}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ApplicantformFilledStatic;
