import React, { useEffect } from "react";
import { useApplication } from "../../hooks/useApplication";
import { useUser } from "../../hooks/useUser";
import { getCompilePaymentFee } from "../../utils/getPaymentAmount";
import { Netsuite } from "../../schema/NetSuiteSchema";
import { getNetsuiteInitialValue } from "../../api/Netsuite";
import NetsuiteLink from "../ui/NetsuiteLink";
import FieldAll from "../ui/FieldAll";
import { TriggerTransactionButton } from "../ui/TriggerTransaction";
import { REACT_APP_URL } from "../../api";

export const AuthorizeAgreementFee = ({ updateManualPayment, editPaymentNotes, updatePaymentTransiction, updateTransictionId, validatepayment }) => {
  const [records, setRecords] = React.useState([]);
  const [arrayOfnesuite, setArrayOfnesuite] = React.useState([]);
  const [netsuiteField, setNetsuiteValue] = React.useState({
    CASHSALE2: {},
    VENDORBILL2: {},
    CUSTOMERDEPOSIT: {},
  });
  const application = useApplication();
  const { roleName = "" } = useUser() || {};
  const { payment, applicationStage, event2transactionNumberDate, event2transactionNumber, event2paymentType, _id, agreement, event2validatedOn, event2transactionId } = application;
  const { event2StripeResponse = [] } = payment || {};
  const { compiled = [] } = agreement || {};
  const signAndPaid = compiled.find((each) => each.action === "Sign and Paid" && each.role === "applicant");
  const { license_fee, totalPaymentFee } = getCompilePaymentFee(application);

  const validated = event2StripeResponse.find((each) => each?.validated === true)?.validated || false;

  const payment_status = event2StripeResponse.find((each) => each?.payment_status === "paid") ? true : false;

  let paymentValue = signAndPaid?.Payment ? signAndPaid?.Payment : applicationStage >= 18 ? true : false;
  if (event2paymentType === "ACH" && !event2transactionNumber) paymentValue = false;

  const isPaymentCompleted = (event2transactionNumberDate && event2paymentType === "ACH") || event2paymentType === "Check" || payment_status || paymentValue;
  const paymentType = event2paymentType || "Stripe";
  const isPaymentNotValidated = !event2validatedOn;
  const isPaymentValidated = !!event2validatedOn;

  const handleNetSuiteChange = (data, event, recordName, column) => {
    let item = 0;
    if (column?.itemId) {
      item = column.itemId;
    }
    const { keyName = "" } = data;
    const { value } = event.target;
    const acceptedType1 = ["text", "date", "number", "dropdown", "textarea"];
    const type = data.type;
    if (acceptedType1.includes(type) && item === 0) {
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [recordName]: { ...initialValue[recordName], [keyName]: value },
      }));
    }
  };

  useEffect(() => {
    let arrayOfnesuite = [];
    Netsuite.sections.forEach((section) => {
      section.questions.forEach((val) => {
        if (val.compilePayment === "true") {
          arrayOfnesuite.push(val);
        }
      });
    });
    const array = arrayOfnesuite.map((section) => {
      return section?.recordName;
    });
    setArrayOfnesuite(arrayOfnesuite);
    setRecords(array);
  }, []);

  useEffect(() => {
    records.forEach(async (records) => {
      let res = await getNetsuiteInitialValue(_id, records);
      let response = res?.data?.data;
      setNetsuiteValue((initialValue) => ({
        ...initialValue,
        [records]: response,
      }));
    });
  }, [records, _id]);

  return (
    <>
      {isPaymentCompleted && (
        <div className="accordion print" id="accordionExample2">
          <div className="accordion-item mb-4">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button py-4 px-4 d-flex justify-content-between "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h2 className="mb-0">Authorize Agreement Fee</h2>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample2">
              <div className="accordion-body p-0">
                <div className="card ">
                  <div id="files-upload ">
                    <div className="card p-0 shadow  border-top-0">
                      {(roleName === "Approving Representative" || roleName === "Approving Accountant") &&
                        arrayOfnesuite.map((v) => {
                          const { recordName, licenseFee } = v;
                          let validatevalueFee = licenseFee !== license_fee;
                          if (recordName === "CUSTOMERDEPOSIT" && Number(license_fee) === 0) {
                            return null;
                          }
                          return (
                            <>
                              {validatevalueFee && (
                                <div className="container">
                                  <div className="row">
                                    <div className="col-lg-12 p-0 m-0">
                                      <div className="accordion" id="accordionExample">
                                        <div id="files-upload Cash Sale">
                                          <div className="accordion-item border-end-0 border-start-0 rounded-0">
                                            <div className="card">
                                              <div className="card-body p-0">
                                                <h2 className="accordion-header" id="PaymentInformation7">
                                                  <button
                                                    className=" accordion-button py-4 px-4 d-flex justify-content-between bg-transparent text-dark fw-normal h2 mb-0 collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#Collapse${v.id}`}
                                                    aria-expanded="false"
                                                    aria-controls={`Collapse${v.id}`}
                                                  >
                                                    {v.header}
                                                  </button>
                                                </h2>
                                                <div id={`Collapse${v.id}`} className="accordion-collapse collapse" aria-labelledby="PaymentInformation7" data-bs-parent="#accordionExample">
                                                  <div className="accordion-body pt-0 p-0">
                                                    <div className="card-col card">
                                                      <div className="card-body pt-0">
                                                        <div className="row">
                                                          <div className="col-lg-12 pt-3 p-0 d-flex flex-row-reverse">
                                                            <div className="form-check p-0">
                                                              <NetsuiteLink applicationID={_id} v={v} />
                                                            </div>
                                                          </div>
                                                          <div className="col-md-12 p-0">
                                                            <div className="row m-0 p-0">
                                                              {v &&
                                                                v.rows.map((col, ind) => {
                                                                  let item = 0;
                                                                  if (col?.itemId) {
                                                                    item = col?.itemId;
                                                                  }
                                                                  let itemsLength = 0;
                                                                  if (netsuiteField?.[recordName]?.items) {
                                                                    itemsLength = netsuiteField?.[recordName]?.items?.length || 0;
                                                                  }

                                                                  return (
                                                                    <>
                                                                      {col.header ? <h3 class="">{col.header}</h3> : ""}
                                                                      {col.label && item !== 0 && item <= itemsLength ? (
                                                                        <div class="">
                                                                          <h3 class="form-label">{col.label}</h3>{" "}
                                                                        </div>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {col.columns.map((data, ind) => {
                                                                        let value = netsuiteField?.[recordName]?.[data.keyName] || "";
                                                                        const enabledfields = ["check", "date", "billdate", "duedate", "check#"];
                                                                        const isStripePayment = !(event2paymentType === "Check" || event2paymentType === "ACH");

                                                                        if (enabledfields.find((key) => key === data.keyName) && isStripePayment) {
                                                                          data.disabled = true;
                                                                        }

                                                                        if (item !== 0) {
                                                                          try {
                                                                            value = netsuiteField?.[recordName]?.items[item - 1]?.[data.keyName];
                                                                          } catch (error) {
                                                                            value = undefined;
                                                                            console.log(error);
                                                                          }

                                                                          if (!value && item !== 0) {
                                                                            return null;
                                                                          }
                                                                        }
                                                                        return (
                                                                          <div className={data.col ? data.col : "col-md-6"}>
                                                                            <FieldAll data={data} handleNetSuiteChange={handleNetSuiteChange} recordName={recordName} col={col} value={value} />
                                                                          </div>
                                                                        );
                                                                      })}
                                                                    </>
                                                                  );
                                                                })}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className={`${event2validatedOn && roleName === "Approing Accountant" ? "card-header  border-bottom p-0" : "card-header  border-bottom pb-0"}`}>
                    {(validated || event2validatedOn) && roleName === "applicant" && (
                      <>
                        <div className="field-col border-bottom ">
                          <h3>Payment Notes:</h3>
                          <p>
                            {event2transactionId && event2transactionId}
                            <br />
                          </p>
                        </div>
                      </>
                    )}

                    <TriggerTransactionButton role={roleName} />

                    {roleName === "Approving Accountant" && event2paymentType && applicationStage === 17 && (
                      <>
                        <div className="mb-4">
                          <label forhtml="transactionId">Payment Notes</label>
                          <textarea className="form-control" id="transactionId" name="transactionId" />
                        </div>
                        <button
                          className="btn btn-block btn-primary"
                          onClick={() => {
                            updateManualPayment("event2paymentType");
                          }}
                        >
                          Received Payment of $<span>{totalPaymentFee}</span>
                        </button>
                      </>
                    )}

                    {isPaymentNotValidated && roleName !== "Approving Accountant" && applicationStage === 17 && (
                      <div className="alert alert-warning text-dark text-center" role="alert">
                        {paymentType === "ACH" && roleName === "applicant" && "The payment is not yet confirmed. If you have submitted ACH, please contact NCRR accounting for an update."}
                        {paymentType === "Check" && roleName === "applicant" && "The payment is not yet confirmed. If you have submitted Check, please contact NCRR accounting for an update."}
                        {paymentType === "Stripe" && roleName === "applicant" && "The payment is not yet confirmed. Awaiting NCRR accounting for an update."}
                        {roleName !== "applicant" && "The payment is not yet confirmed. Awaiting NCRR accounting for an update."}
                      </div>
                    )}
                    {roleName === "Approving Accountant" &&
                      applicationStage > 17 &&
                      (editPaymentNotes ? (
                        <>
                          <div className="mb-4">
                            <label forhtml="transactionId">Update Payment Notes</label>
                            <textarea defaultValue={event2transactionId} className="form-control" id="transactionId" name="transactionId" />
                          </div>
                          <button
                            className="btn btn-link"
                            style={{
                              textDecoration: "underline",
                              pointerEvents: "fill",
                            }}
                            onClick={() => {
                              updatePaymentTransiction("event2paymentType");
                            }}
                          >
                            <i className="far fa-check"></i> Save
                          </button>
                          <a
                            className="btn btn-link text-warning"
                            style={{
                              textDecoration: "underline",
                              pointerEvents: "fill",
                            }}
                            href={REACT_APP_URL + "/compiledAgreement/" + _id}
                          >
                            <i className="far fa-ban"></i> Cancel
                          </a>
                        </>
                      ) : (
                        <>
                          <div className="mb-4 text-muted">
                            <label forhtml="transactionId">Payment Notes</label>
                            <textarea defaultValue={event2transactionId} disabled className="form-control mt-2" id="transactionId" name="transactionId" />
                          </div>
                          <button
                            className="btn btn-link"
                            style={{
                              textDecoration: "underline",
                              pointerEvents: "fill",
                            }}
                            onClick={updateTransictionId}
                          >
                            <i className="far fa-pen"></i> Edit
                          </button>
                        </>
                      ))}

                    {roleName === "Approving Accountant" && applicationStage > 1 && applicationStage !== 1 && !event2paymentType && !event2transactionId && (
                      <>
                        <div className="px-0 card-header bg-transparent border-0 pb-0"></div>

                        <div className="mb-4">
                          <label forhtml="transactionId">Payment Notes</label>
                          <textarea className="form-control" id="transactionId" name="transactionId" />
                        </div>
                        <button
                          className="btn btn-block btn-primary"
                          onClick={() => {
                            updateManualPayment("event2paymentType");
                          }}
                        >
                          Received Payment of $<span>{totalPaymentFee}</span>
                        </button>
                      </>
                    )}

                    {applicationStage > 1 && roleName !== "Approving Accountant" && (
                      <>
                        {!!event2validatedOn && (
                          <div className="alert alert-success  text-dark" role="alert">
                            Successfully acknowledged payment. The application has now moved on to the next stage.
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!editPaymentNotes && !validated && !event2validatedOn && roleName === "Approving Accountant" && event2transactionId && (
                    <div className="card-footer py-0 justify-content-center bg-primary ">
                      <a className="d-block text-white btn py-4 px-0" tabIndex="-1" role="button" onClick={validatepayment}>
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            {"Authorize"} <i className="fal fa-chevron-right ms-2"></i>
                          </span>
                        </span>
                      </a>
                    </div>
                  )}

                  {(validated || event2validatedOn || (applicationStage >= 18 && applicationStage !== 26)) && (
                    <div className="card-footer py-0 justify-content-center bg-success">
                      <a className="d-block text-white btn border-0 disabled py-4 px-0" tabIndex="-1" role="button" aria-disabled="true">
                        <span className="d-flex justify-content-between align-items-center">
                          <span>
                            <i className="fal fa-check"></i> {"Authorized"}
                          </span>
                          <span>By Accountant on {event2validatedOn && <small> {event2validatedOn}</small>}</span>
                        </span>
                      </a>
                    </div>
                  )}
                  {!event2validatedOn && !validated && !event2paymentType && roleName !== "Approving Accountant" ? (
                    <div
                      className="card-footer justify-content-center "
                      style={{
                        backgroundColor: "rgb(233 237 241)",
                      }}
                    >
                      Awaiting for Accountant
                      <i className="fal fa-chevron-right ms-2"></i>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
